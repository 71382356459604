export default {
	province_list: {
		110000: '北京市',
		120000: '天津市',
		130000: '河北省',
		140000: '山西省',
		150000: '内蒙古自治区',
		210000: '辽宁省',
		220000: '吉林省',
		230000: '黑龙江省',
		310000: '上海市',
		320000: '江苏省',
		330000: '浙江省',
		340000: '安徽省',
		350000: '福建省',
		360000: '江西省',
		370000: '山东省',
		410000: '河南省',
		420000: '湖北省',
		430000: '湖南省',
		440000: '广东省',
		450000: '广西壮族自治区',
		460000: '海南省',
		500000: '重庆市',
		510000: '四川省',
		520000: '贵州省',
		530000: '云南省',
		// 540000: '西藏自治区',
		610000: '陕西省',
		620000: '甘肃省',
		630000: '青海省',
		640000: '宁夏回族自治区'
		// 650000: '新疆维吾尔自治区',
		// 710000: '台湾省',
		// 810000: '香港特别行政区',
		// 820000: '澳门特别行政区',
		// 900000: '海外'
	},
	hospital_list: {
		1101000: '爱康国宾北京白云路分院',
		1101001: '爱康国宾北京中关村方正国际大厦分院',
		1101002: '爱康国宾北京亚运村慧忠北里分院',
		1101003: '爱康国宾北京顺平分院',
		1101004: '爱康国宾北京丽都分院',
		1101005: '爱康国宾北京总部基地分院',
		1101006: '爱康国宾北京建国门分院',
		1101007: '爱康国宾北京西直门分院5层',
		1101008: '爱康国宾北京西直门分院4层',
		1101009: '爱康国宾北京安华桥分院',
		11010010: '爱康国宾北京酒仙桥分院',
		11010011: '爱康国宾北京郡王府分院',
		11010012: '爱康国宾北京磁器口分院',
		11010092: '美年大健康北京西直门分院',
		11010093: '美年大健康北京大望路分院',
		11010094: '美年大健康北京牡丹园分院',
		11010095: '美年大健康北京宣武门分院',
		11010096: '美年大健康北京太阳宫分院',
		11010097: '美年大健康北京魏公村分院',
		11010098: '美年大健康北京酒仙桥分院',
		12010025: '爱康国宾天津南京路吉利大厦分院',
		12010026: '爱康国宾天津围堤道峰汇广场分院',
		12010027: '爱康国宾天津六纬路东润名邸分院',
		12010028: '爱康国宾天津南马路悦府广场分院',
		21010032: '爱康国宾沈阳青年大街分院',
		21010033: '爱康国宾沈阳太原街分院',
		22010046: '爱康国宾长春建设分院',
		31010017: '爱康国宾上海西藏南路老西门分院',
		31010018: '爱康国宾上海曹家渡一品分院',
		31010019: '爱康国宾上海浦东八佰伴分院',
		31010020: '爱康国宾上海外滩延安东路分院',
		31010021: '爱康国宾上海陆家嘴分院',
		31010022: '爱康国宾上海中山公园南延安西路分院',
		31010023: '爱康国宾上海中环一品分院',
		31010024: '爱康国宾上海五角场万达广场分院',
		32010034: '爱康国宾南京鼓楼分院',
		32010035: '爱康国宾南京江宁分院',
		32010036: '爱康国宾南京浦口大道新城总部大厦分院',
		32010037: '爱康国宾南京新街口分院',
		32020047: '爱康国宾无锡新吴茂业分院',
		32050050: '爱康国宾苏州东环分院',
		32050051: '爱康国宾苏州高新分院',
		32110049: '爱康国宾镇江长江路文广分院',
		32120052: '爱康国宾泰州青年南路凤城国际分院',
		33010060: '爱康国宾杭州滨江江南大道分院',
		33010061: '爱康国宾杭州钱江世纪城广孚中心分院',
		33010062: '爱康国宾杭州文晖分院',
		33010063: '爱康国宾杭州西溪分院',
		33020064: '爱康国宾宁波鼓楼分院',
		33030065: '爱康国宾温州国贸分院',
		34020059: '爱康国宾芜湖镜湖分院',
		34030091: '美年大健康蚌埠分院',
		34160099: '美年大健康亳州蒙城分院',
		35010066: '爱康国宾福州鼓楼分院',
		37020053: '爱康国宾青岛市北CBD西王大厦分院',
		37060054: '爱康国宾烟台开发区长江路分院',
		37060055: '爱康国宾烟台莱山区港城东大街分院',
		37060056: '爱康国宾烟台芝罘区世茂分院',
		37070057: '爱康国宾潍坊东方路分院',
		37070058: '爱康国宾潍坊新华路分院',
		37100067: '爱康国宾威海滨海分院',
		42010038: '爱康国宾武汉金墩分院',
		42090068: '爱康国宾孝感银泰分院',
		43010069: '爱康国宾长沙湘府东路双塔国际分院',
		43010070: '爱康国宾长沙河西西中心分院',
		43010071: '爱康国宾长沙八一桥华天酒店分院',
		43020072: '爱康国宾株洲天元华天大酒店分院',
		43080073: '爱康国宾张家界天门山华天大酒店分院',
		44010013: '爱康国宾广州林和西中泰分院',
		44010014: '爱康国宾广州花城大道南天广场分院五层',
		44010015: '爱康国宾广州环市东分院三层',
		44010016: '爱康国宾广州天河华港花园分院',
		44030074: '爱康国宾深圳罗湖分院',
		44030075: '爱康国宾深圳科技园科兴分院',
		44030076: '爱康国宾深圳福田分院',
		44030077: '爱康国宾深圳华强分院',
		44060078: '爱康国宾佛山禅城分院',
		44190079: '爱康国宾东莞南城财富广场分院',
		50010029: '爱康国宾重庆沙坪坝区分院',
		50010030: '爱康国宾重庆龙湖源著欣康分院',
		50010031: '爱康国宾重庆黄泥磅揽胜国际分院',
		51010039: '爱康国宾成都高新分院',
		51010040: '爱康国宾成都城南分院',
		51010041: '爱康国宾成都骡马市分院',
		51010042: '爱康国宾成都茶店子居然之家分院',
		51010043: '爱康国宾成都红照壁航天科技分院',
		51010044: '爱康国宾成都龙泉百悦国际中心分院',
		51060081: '爱康国宾德阳文庙广场文华楼分院一线',
		51070080: '爱康国宾绵阳高新火炬广场分院',
		52010082: '爱康国宾贵阳观山湖分院',
		52020088: '爱康国宾贵州六盘水国贸分院',
		52030083: '爱康国宾遵义银河路分院',
		52050087: '爱康国宾毕节松山路三江花园分院',
		52060086: '爱康国宾铜仁金铜国际分院',
		61010045: '爱康国宾西安曲江分院',
		64040090: '爱康国宾固原华祺饭店分院',
		110100271: '美年大健康北京建国门分院',
		110100458: '太阳宫分院',
		110100459: '大望路分院',
		110100460: '宣武门分院',
		110100461: '西直门分院',
		110100462: '酒仙桥分院',
		110100463: '牡丹园分院',
		110100464: '魏公村分院',
		110100840: '亚运村分院(2分院)',
		110100841: '西直门分院(3分院)',
		110100842: '世纪城分院(4分院)',
		110100843: '大北窑分院(5分院)',
		110100844: '知春路分院(7分院)',
		110100845: '望京分院(8分院)',
		110100846: '慈云寺分院(12分院)',
		110100847: '公主坟分院（15分院）',
		110100848: '上地硅谷分院（20分院）',
		110100892: '北京瑞慈三里屯体检中心',
		110100949: '爱康国宾北京丽都分院',
		110100950: '爱康国宾北京建国门分院',
		110100951: '爱康国宾北京中关村方正大厦分院',
		110100952: '爱康国宾北京酒仙桥分院',
		110100953: '爱康国宾北京宣武门分院',
		110100954: '爱康国宾北京西直门分院5层',
		110100955: '爱康国宾北京西直门分院4层',
		110100956: '爱康国宾北京亚运村慧忠北里分院',
		110100957: '爱康国宾北京白石桥分院',
		110100958: '爱康国宾北京磁器口分院',
		110100959: '爱康国宾北京郡王府分院',
		110100960: '爱康国宾北京知春路分院',
		110100961: '爱康国宾北京安华桥分院',
		110100962: '爱康国宾北京总部基地分院',
		110100963: '爱康国宾北京顺平分院',
		110100964: '爱康国宾北京白云路分院',
		120100205: '美年大健康天津友谊路分院',
		120100206: '美年大健康天津和平美佳分院',
		120100207: '美年大健康天津滨海分院',
		120100208: '美年大健康天津塘沽分院',
		120100209: '美年大健康天津鼓楼分院',
		120100210: '美年大健康天津八里台分院',
		120100465: '大光明桥分院',
		120100466: '鼓楼分院',
		120100467: '塘沽分院',
		120100849: '小白楼分院',
		120100850: '南门外分院',
		120100851: '滨海分院',
		120100994: '爱康国宾天津南京路吉利大厦体检分院',
		120100995: '爱康国宾天津东润大厦体检分院',
		120100996: '爱康国宾天津峰汇分院',
		120100997: '爱康国宾天津南马路悦府广场分院',
		130100412: '美年大健康石家庄长安分院',
		130100413: '美年大健康石家庄桥西分院',
		130100414: '美年大健康石家庄裕华分院',
		130100468: '桥西分院',
		130100469: '裕华分院',
		130100470: '长安分院',
		130200202: '美年大健康唐山路南分院',
		130200203: '美年大健康唐山高新分院',
		130200471: '高新分院',
		130200472: '世博大厦分院',
		130300473: '秦皇岛分院',
		130400122: '美年大健康邯郸分院',
		130400474: '邯山分院',
		130600269: '美年大健康保定分院',
		130600475: '保定分院',
		130600476: '保定慈铭',
		130900273: '美年大健康沧州分院',
		130900477: '沧州分院',
		131000324: '美年大健康廊坊分院',
		131000478: '廊坊分院',
		140100479: '双东分院（迎泽）',
		140100480: '长风分院',
		140100481: '漪汾分院',
		140100482: '太原万达分院',
		140700483: '迎宾分院',
		140800248: '美年大健康运城分院',
		140800484: '运城分院',
		141000325: '美年大健康临汾分院',
		141000485: '临汾分院',
		141100338: '美年大健康吕梁分院',
		141100486: '吕梁分院',
		150100135: '美年大健康呼和浩特玉泉分院',
		150100136: '美年大健康呼和浩特东汇分院',
		150100487: '玉泉分院',
		150100488: '万铭分院',
		150200267: '美年大健康包头万达广场分院',
		150200268: '美年大健康包头白云路分院',
		150200489: '包头分院',
		150200490: '包头万达分院',
		150400491: '赤峰慈铭分院',
		150400492: '赤峰分院',
		150500212: '美年大健康通辽滨河分院',
		150500213: '美年大健康通辽分院',
		150500493: '通辽慈铭分院',
		150500494: '通辽旗舰分院',
		150700309: '美年大健康呼伦贝尔分院',
		210100431: '美年大健康沈阳奥亚分院',
		210100432: '美年大健康沈阳和平分院',
		210100433: '美年大健康沈阳皇姑分院',
		210100434: '美年大健康沈阳浑南分院',
		210100435: '美年大健康沈阳沈河分院',
		210100436: '美年大健康沈阳铁西分院',
		210100495: '铁西分院',
		210100496: '双和分院',
		210100497: '沈河分院',
		210100498: '和平分院',
		210100499: '浑南分院',
		210100500: '沈阳奥亚分院',
		210100501: '皇姑分院',
		210100893: '沈阳瑞慈铁西体检中心',
		210200424: '美年大健康大连精品分院',
		210200425: '美年大健康大连精英分院',
		210200426: '美年大健康大连旅顺分院',
		210200427: '美年大健康大连明珠分院',
		210200428: '美年大健康大连普兰分院',
		210200502: '星海明珠分院',
		210200503: '普兰店分院',
		210200504: '大连滨海路精品分院',
		210200505: '大连滨海路精英分院',
		210200506: '旅顺分院',
		210200852: '中南路分院',
		210200853: '星海分院',
		210200854: '软件园分院',
		210300423: '美年大健康鞍山分院',
		210300507: '鞍山分院',
		210400429: '美年大健康抚顺分院',
		210400508: '抚顺分院',
		210500509: '本溪分院',
		210600110: '美年大健康丹东分院',
		210600510: '丹东分院',
		210700146: '美年大健康锦州太和分院',
		210700511: '香谢丽分院',
		210800512: '营口分院(美康)',
		210900117: '美年大健康阜新分院',
		210900513: '阜新分院',
		211000154: '美年大健康辽阳江北分院',
		211000514: '辽阳分院',
		211100430: '美年大健康盘锦分院',
		211100515: '盘锦分院',
		211200364: '美年大健康铁岭分院',
		211200516: '铁岭分院',
		211300276: '美年大健康朝阳分院',
		211300517: '朝阳分院',
		211400137: '美年大健康葫芦岛分院',
		211400310: '美年大健康怀化分院',
		211400518: '葫芦岛分院',
		220100249: '美年大健康长春南关分院',
		220100250: '美年大健康长春朝阳分院',
		220100519: '南关分院',
		220100520: '奥亚分院',
		220100521: '宇宸分院',
		220100855: '浦东路分院',
		220100856: '南湖分院',
		220100857: '百维慈康医院',
		220200141: '美年大健康吉林丰满分院',
		220200522: '丰满分院',
		220200523: '昌邑分院',
		220700193: '美年大健康松原分院',
		220700524: '松原分院',
		230100525: '道外分院',
		230100526: '南岗分院',
		230100527: '美铭香坊体检中心',
		310100441: '美年大健康上海奉贤分院',
		310100442: '美年大健康上海嘉定分院',
		310100443: '美年大健康上海静安分院',
		310100444: '美年大健康上海灵石路分院',
		310100445: '美年大健康上海齐鲁分院',
		310100446: '美年大健康上海松江分院',
		310100447: '美年大健康上海苏河分院',
		310100448: '美年大健康上海天山分院',
		310100449: '美年大健康上海旺角分院',
		310100450: '美年大健康上海五角场分院',
		310100451: '美年大健康上海小木桥分院',
		310100452: '美年大健康上海宜山分院',
		310100453: '美年大健康上海张江分院',
		310100454: '美年大健康上海中山公园分院',
		310100528: '徐汇总院（美年）',
		310100529: '长宁分院（美健）',
		310100530: '闵行分院（美宜）',
		310100531: '浦东二分院（齐鲁）',
		310100532: '闸北分院（美恒）',
		310100533: '静安分院（美锦）',
		310100534: '杨浦分院（美阳）',
		310100535: '赢华分院（赢华）',
		310100536: '瑞美静安中心（瑞美）',
		310100537: '瑞美黄浦中心（康盟）',
		310100538: '瑞美虹口中心（瑞至）',
		310100539: '美智门诊部（美智）',
		310100858: '黄埔分院',
		310100859: '徐汇分院（卓越）',
		310100860: '浦东分院（至诚）',
		310100861: '控江路分院(初元)',
		310100862: '真如分院(正元)',
		310100894: '上海瑞慈普陀体检中心',
		310100895: '上海瑞慈张江体检中心',
		310100896: '上海瑞慈杨浦体检中心',
		310100897: '上海瑞慈普陀体检中心',
		310100898: '上海瑞慈松江体检中心',
		310100899: '上海瑞慈青浦体检中心',
		310100900: '上海瑞慈漕河泾体检中心',
		310100901: '上海瑞慈徐汇体检中心',
		310100902: '上海瑞慈陆家嘴体检中心',
		310100903: '上海瑞慈福山路体检中心',
		310100904: '上海瑞慈静安体检中心',
		310100905: '上海瑞慈闵行体检中心',
		310100906: '上海瑞慈嘉定体检中心',
		310100907: '上海瑞慈奉贤体检中心',
		310100908: '上海幸元会',
		310100965: '爱康国宾上海浦东八佰伴分院',
		310100966: '爱康国宾上海杨浦五角场分院',
		310100967: '爱康国宾上海陆家嘴分院',
		310100968: '爱康国宾上海中山公园南延安西路分院',
		310100969: '爱康国宾上海曹家渡一品分院',
		310100970: '爱康国宾上海中环一品分院',
		310100971: '爱康国宾上海西藏南路老西门分院',
		310100972: '爱康国宾上海外滩延安东路分院',
		320100166: '美年大健康南京江宁分院',
		320100167: '美年大健康南京鼓楼分院',
		320100168: '美年大健康南京河西分院',
		320100169: '美年大健康南京汉中路分院',
		320100540: '鼓楼分院',
		320100541: '江宁分院',
		320100863: '南京一分院',
		320100864: '南京高端店',
		320100865: '南京奥体店',
		320100909: '南京瑞慈秦淮体检中心',
		320100910: '南京瑞慈建邺体检中心',
		320100911: '南京瑞慈中航体检中心（幸元会)',
		320100976: '爱康国宾南京鼓楼分院',
		320100977: '爱康国宾南京新街口分院',
		320100978: '南京爱康国宾江宁分院',
		320200220: '美年大健康无锡华康分院',
		320200221: '美年大健康无锡建筑分院',
		320200222: '美年大健康无锡美华分院',
		320200223: '美年大健康无锡疗养院分院',
		320200224: '美年大健康无锡五爱广场分院',
		320200542: '建筑路分院',
		320200543: '美华分院（旗舰店）',
		320200544: '新区分院（华康）',
		320200545: '淮安慈铭华康门诊分院',
		320200546: '无锡五爱广场分院',
		320200547: '宜兴分院',
		320200912: '无锡瑞慈滨湖体检中心',
		320200983: '爱康国宾无锡新吴茂业分院',
		320300379: '美年大健康徐州分院',
		320300913: '徐州瑞慈体检中心',
		320400275: '美年大健康常州分院',
		320400548: '常州分院',
		320400914: '常州瑞慈体检中心',
		320400980: '爱康国宾常州通江南路体检分院',
		320500194: '美年大健康苏州东环分院',
		320500195: '美年大健康苏州美新分院',
		320500196: '美年大健康苏州相城分院',
		320500197: '美年大健康苏州长江路分院',
		320500549: '东环分院',
		320500550: '长江分院',
		320500551: '狮山分院',
		320500552: '相城分院',
		320500553: '昆山分院',
		320500554: '太仓分院',
		320500555: '常熟分院',
		320500915: '苏州瑞慈高新体检中心',
		320500916: '苏州瑞慈园区体检中心',
		320500979: '爱康国宾苏州东环体检分院',
		320600173: '美年大健康南通钟秀分院',
		320600174: '美年大健康南通分院',
		320600556: '崇川分院',
		320600557: '钟秀分院',
		320600917: '南通瑞慈北大街体检中心',
		320600918: '南通瑞慈港闸体检中心',
		320600919: '南通瑞慈滨江体检中心',
		320600920: '南通瑞慈医院体检中心',
		320600921: '南通瑞慈文峰城市广场体检中心',
		320600922: '南通瑞慈瑞运体检中心',
		320700558: '连云港分院',
		320800559: '淮安分院',
		320800560: '淮安铭泽分院（一店）',
		320800561: '淮安铭泽分院（二店）',
		320900385: '美年大健康盐城分院',
		320900562: '盐城分院',
		320900923: '盐城瑞慈瑞景体检中心',
		321000421: '美年大健康扬州分院',
		321000924: '扬州瑞慈体检中心',
		321100397: '美年大健康镇江分院',
		321100563: '镇江分院',
		321100925: '镇江瑞慈体检中心',
		321200363: '美年大健康泰州分院',
		321200564: '海陵分院',
		321300378: '美年大健康宿迁分院',
		321300926: '宿迁瑞慈体检中心',
		330100123: '美年大健康杭州萧山分院',
		330100124: '美年大健康杭州临安分院',
		330100125: '美年大健康杭州钱江分院',
		330100126: '美年大健康杭州延安路分院',
		330100127: '美年大健康杭州桐庐分院',
		330100128: '美年大健康杭州滨江分院',
		330100129: '美年大健康杭州城北分院',
		330100130: '美年大健康杭州西溪分院',
		330100131: '美年大健康杭州黄龙分院',
		330100565: '西湖分院',
		330100566: '滨江分院',
		330100567: '萧山分院',
		330100568: '美新分院',
		330100569: '黄龙分院',
		330100570: '西溪分院',
		330100866: '友好分院',
		330100867: '凯旋分院',
		330100927: '杭州瑞慈延安体检中心',
		330100989: '爱康国宾杭州西溪分院',
		330100990: '爱康国宾杭州文晖分院',
		330100991: '爱康国宾杭州滨江江南大道分院',
		330200175: '美年大健康宁波慈溪分院',
		330200176: '美年大健康宁波鄞州分院',
		330200177: '美年大健康宁波分院',
		330200571: '海曙分院',
		330200572: '慈溪分院',
		330200573: '易和分院',
		330200868: '江东店',
		330200869: '鄞州分院',
		330200992: '爱康国宾宁波鼓楼分院',
		330300368: '美年大健康温州分院',
		330300369: '美年大健康温州平阳分院',
		330300574: '温州分院',
		330400145: '美年大健康嘉兴分院',
		330400575: '海宁分院',
		330400576: '嘉兴分院',
		330500928: '浙江湖州瑞慈体检中心',
		330600358: '美年大健康绍兴分院',
		330600577: '绍兴越城分院',
		330700316: '美年大健康金华分院',
		330700578: '金华分院',
		330700579: '慈铭分院',
		330700870: '慈铭体检中心',
		330700871: '金华美年大健康',
		330800182: '美年大健康衢州分院',
		330800580: '衢州分院',
		331000361: '美年大健康台州椒江分院',
		331000581: '温岭分院',
		331000582: '椒江分院（美兆）',
		331000583: '临海分院',
		331100584: '丽水分院',
		340100132: '美年大健康合肥滨湖分院',
		340100133: '美年大健康合肥包河分院',
		340100585: '包河分院（美欣）',
		340100586: '滨湖分院',
		340100587: '博瑞康分院',
		340100872: '高新区',
		340100929: '合肥瑞慈蜀山体检中心',
		340100930: '合肥瑞慈高新体检中心',
		340200588: '芜湖分院',
		340200589: '万达分院',
		340200982: '爱康国宾镜湖分院',
		340400312: '美年大健康淮南分院',
		340400590: '淮南分院',
		340500339: '美年大健康马鞍山分院',
		340500340: '美年大健康当涂分院',
		340500591: '马鞍山分院',
		340600311: '美年大健康淮北分院',
		340700592: '铜陵分院',
		340800593: '安庆分院',
		341000408: '美年大健康黄山分院',
		341100287: '美年大健康滁州分院',
		341100594: '滁州分院',
		341200407: '美年大健康阜阳分院',
		341200595: '阜阳分院',
		341300596: '宿州分院',
		341500333: '美年大健康六安分院',
		341500597: '六安分院',
		341600100: '美年大健康亳州分院',
		341600598: '亳州分院',
		341600599: '蒙城分院',
		341800381: '美年大健康宣城分院',
		341800600: '宣城分院',
		350100601: '福州分院',
		350100993: '爱康国宾福州鼓楼分院',
		350200183: '美年大健康厦门湖里分院',
		350200184: '美年大健康厦门思明分院',
		350200356: '美年大健康厦门银城分院',
		350200602: '嘉禾分院',
		350200603: '湖里分院',
		350200931: '厦门瑞慈体检中心',
		350300604: '莆田分院',
		350400355: '美年大健康三明分院',
		350400605: '三明分院',
		350500349: '美年大健康泉州分院',
		350500350: '美年大健康泉州现代分院',
		350500351: '美年大健康南安分院',
		350500352: '美年大健康泉州安溪分院',
		350500606: '晋江分院',
		350500607: '南安分院',
		350500608: '泉州分院',
		350500609: '现代分院',
		350500932: '泉州瑞慈体检中心',
		350600409: '美年大健康漳州分院',
		350600610: '漳州分院',
		350800334: '美年大健康龙岩分院',
		350800611: '龙岩分院',
		350900344: '美年大健康宁德分院',
		350900612: '宁德分院',
		360100163: '美年大健康南昌红谷分院',
		360100164: '美年大健康南昌青山湖分院',
		360100165: '美年大健康南昌美康分院',
		360100613: '美康分院',
		360100614: '倍邦分院',
		360100615: '医药谷分院',
		360100616: '红谷滩分院',
		360200617: '景德镇分院',
		360400320: '美年大健康九江分院',
		360400618: '九江分院',
		360500377: '美年大健康新余分院',
		360500619: '新余分院',
		360600620: '鹰潭分院',
		360700295: '美年大健康赣州分院',
		360700621: '赣州分院',
		360800313: '美年大健康吉安分院',
		360800622: '吉安分院',
		360900246: '美年大健康宜春分院',
		360900623: '高安分院',
		360900624: '宜春分院',
		361000625: '抚州分院',
		361100626: '鄱阳分院',
		361100627: '美康分院',
		370100142: '美年大健康济南市中分院',
		370100143: '美年大健康济南明湖分院',
		370100628: '市中分院',
		370100629: '燕山分院',
		370100630: '明湖分院',
		370100631: '章丘分院',
		370100873: '大明湖分院',
		370100874: '奥体中心分院',
		370100933: '济南瑞慈体检中心',
		370200178: '美年大健康青岛徐州路分院',
		370200179: '美年大健康青岛市南分院',
		370200180: '美年大健康青岛银海明珠分院',
		370200632: '旗舰分院',
		370200875: '青岛分院',
		370200934: '青岛瑞慈市南体检中心',
		370300406: '美年大健康淄博分院',
		370300633: '淄博分院',
		370400391: '美年大健康枣庄分院',
		370400634: '滕州分院',
		370500635: '合作分院',
		370600242: '美年大健康烟台福田分院',
		370600243: '美年大健康烟台开发分院',
		370600244: '美年大健康烟台莱山分院',
		370600383: '美年大健康海阳分院',
		370600636: '芝罘福田分院',
		370600637: '开发区分院',
		370600638: '莱州分院',
		370600639: '莱山慈铭分院',
		370600935: '烟台瑞慈体检中心',
		370600998: '爱康国宾烟台莱山区港城东大街体检分院',
		370600999: '爱康国宾烟台开发区长江路体检分院',
		370700214: '美年大健康诸城分院',
		370700215: '美年大健康潍坊高新分院',
		370700216: '美年大健康潍坊分院',
		370700640: '高新睿尔分院',
		370700641: '奎文分院',
		370800642: '开泰门诊部',
		370900200: '美年大健康泰安分院',
		370900201: '美年大健康泰安温泉路分院',
		370900204: '美年大健康滕州分院',
		370900211: '美年大健康天水分院',
		370900643: '泰安分院',
		370900644: '肥城分院',
		371000365: '美年大健康威海二院',
		371000366: '美年大健康威海分院',
		371000645: '威海分院',
		371100353: '美年大健康日照分院',
		371100646: '莒县分院',
		371100647: '五莲分院',
		371100648: '莒县分院',
		371100649: '东港分院',
		371300156: '美年大健康临沂万兴都分院',
		371300157: '美年大健康临沂金阳花园分院',
		371300327: '美年大健康临沂沂水分院',
		371300328: '美年大健康临沂平邑分院',
		371300329: '美年大健康临沂莒南分院',
		371300330: '美年大健康费县分院',
		371300331: '美年大健康临沂沂南分院',
		371300650: '金阳分院',
		371300651: '沂南县分院',
		371300652: '沂水县分院',
		371300653: '万兴都分院',
		371300876: '兰山分院',
		371300877: '南坊分院',
		371400290: '美年大健康德州分院',
		371400654: '德州分院',
		371500155: '美年大健康聊城分院',
		371500655: '聊城分院',
		371700437: '美年大健康菏泽巨野分院',
		371700438: '美年大健康郓城分院',
		371700439: '美年大健康菏泽曹县分院',
		371700440: '美年大健康菏泽分院',
		371700656: '曹县分院',
		371700657: '巨野分院',
		371700658: '单县分院',
		371700659: '菏泽分院',
		410100255: '美年大健康郑州金水分院',
		410100256: '美年大健康郑州中州分院',
		410100257: '美年大健康郑州商都分院',
		410100258: '美年大健康郑州二七分院',
		410100259: '美年大健康郑州高新分院',
		410100660: '金水分院',
		410100661: '中州分院',
		410100662: '二七分院',
		410100663: '商都分院',
		410200147: '美年大健康开封分院',
		410200664: '开封分院',
		410300159: '美年大健康洛阳西工分院',
		410300160: '美年大健康洛阳高新分院',
		410300161: '美年大健康洛阳洛龙分院',
		410300665: '西工分院',
		410300666: '洛龙体育场分院',
		410300667: '洛阳高新分院',
		410400668: '建宏分院',
		410500669: '文峰分院',
		410600670: '鹤壁分院',
		410700239: '美年大健康长垣分院',
		410700240: '美年大健康新乡宝龙分院',
		410700241: '美年大健康新乡玫瑰园分院',
		410700671: '玫瑰园分院',
		410700672: '宝龙分院',
		410800315: '美年大健康焦作分院',
		410800673: '焦作分院',
		411000380: '美年大健康许昌分院',
		411000674: '空港分院',
		411000675: '长葛分院',
		411000676: '长葛分院',
		411100677: '漯河分院',
		411200354: '美年大健康三门峡分院',
		411200678: '三门峡分院',
		411300342: '美年大健康南阳分院',
		411300343: '美年大健康南阳社旗分院',
		411300679: '社旗分院',
		411300680: '南阳分院',
		411400357: '美年大健康商丘分院',
		411400681: '奥亚分院',
		411400682: '商丘分院',
		411600404: '美年大健康周口分院',
		411600683: '周口分院',
		411700265: '美年大健康驻马店分院',
		420100225: '美年大健康武汉硚口分院',
		420100226: '美年大健康武汉新洲分院',
		420100227: '美年大健康武汉光谷分院',
		420100228: '美年大健康武汉亿生分院',
		420100229: '美年大健康武汉汉口分院',
		420100230: '美年大健康武汉江夏分院',
		420100371: '美年大健康武汉四新分院',
		420100686: '汉口分院',
		420100687: '硚口分院',
		420100688: '汉阳分院',
		420100689: '武昌分院',
		420100690: '江夏分院',
		420100691: '亿生分院',
		420100692: '高信分院',
		420100693: '光谷分院',
		420100694: '新州分院',
		420100878: '汉口分院',
		420100879: '光谷分院',
		420100880: '徐东分院',
		420100881: '汉阳分院',
		420100882: '奥亚分院',
		420100936: '武汉瑞慈硚口体检中心',
		420100937: '武汉瑞慈武昌分院',
		420200138: '美年大健康黄石分院',
		420200139: '美年大健康阳新分院',
		420200695: '黄石分院',
		420200696: '阳新分院',
		420300360: '美年大健康十堰分院',
		420300698: '十堰分院',
		420500699: '宜昌分院',
		420600700: '大庆西路分院',
		420700115: '美年大健康鄂州分院',
		420700701: '鄂州分院',
		420800318: '美年大健康荆门分院',
		420800702: '钟祥美年大健康体检中心',
		420900375: '美年大健康孝感分院',
		421000319: '美年大健康荆州分院',
		421000703: '荆州分院',
		421100417: '美年大健康黄冈分院',
		421100418: '美年大健康黄冈浠水分院',
		421100419: '美年大健康黄冈蕲春分院',
		421100704: '黄冈分院',
		421200374: '美年大健康咸宁分院',
		421200705: '咸宁分院',
		421300198: '美年大健康随州分院',
		421300706: '广水分院',
		421300707: '随州分院',
		422800416: '美年大健康恩施分院',
		422800708: '恩施分院',
		430100251: '美年大健康长沙天心分院',
		430100252: '美年大健康长沙定王分院',
		430100253: '美年大健康长沙岳麓分院',
		430100254: '美年大健康长沙雨花分院',
		430100711: '雨花分院',
		430100712: '芙蓉分院',
		430100713: '天心分院',
		430100714: '华菱分院',
		430100715: '岳麓分院',
		430100938: '长沙瑞慈芙蓉体检中心',
		430100939: '长沙瑞慈天心体检中心',
		430200264: '美年大健康株洲分院',
		430200716: '栗雨分院',
		430200717: '芦淞分院',
		430300238: '美年大健康湘潭岳塘分院',
		430300718: '雨湖分院',
		430300719: '岳塘分院',
		430400307: '美年大健康衡阳分院',
		430400720: '衡阳门诊部',
		430600247: '美年大健康岳阳分院',
		430600721: '巴陵分院',
		430700274: '美年大健康常德分院',
		430700722: '常德分院',
		430800394: '美年大健康张家界分院',
		430800723: '张家界分院',
		430900387: '美年大健康益阳分院',
		430900724: '益阳分院',
		431000277: '美年大健康郴州分院',
		431100725: '永州分院',
		431300336: '美年大健康娄底分院',
		440100298: '美年大健康广州黄埔大道贵宾分院',
		440100299: '美年大健康广州环市东路分院',
		440100300: '美年大健康广州天河东路分院',
		440100301: '美年大健康广州番禺分院',
		440100302: '美年大健康广州珠江新城分院',
		440100726: '番禺分院',
		440100883: '体育中心分院',
		440100884: '卓越分院',
		440100940: '广州瑞慈西塔体检中心',
		440100941: '广州瑞慈西塔体检中心',
		440100942: '广州瑞慈天河体检中心',
		440100973: '爱康国宾广州天河华港花园体检分院',
		440200727: '雍华健康体检中心',
		440300185: '美年大健康深圳美阳门诊部',
		440300186: '美年大健康深圳中港城门诊部',
		440300187: '美年大健康深圳美益康门诊部',
		440300188: '美年大健康深圳美年门诊部',
		440300189: '美年大健康深圳美兆门诊部',
		440300190: '美年大健康深圳红岭门诊部',
		440300191: '美年大健康深圳美宝健康体检中心',
		440300192: '美年大健康深圳美盛门诊部',
		440300359: '美年大健康深圳美辰门诊部',
		440300728: '美年门诊部',
		440300729: '财富大厦门诊部',
		440300730: '美阳分院',
		440300731: '美盛分院',
		440300732: '美辰分院',
		440300733: '中港城门诊部',
		440300734: '红岭门诊部',
		440300735: '南山门诊部',
		440300736: '美益康门诊部',
		440300737: '招商门诊部（蛇口）',
		440300885: '海松（福田）分院',
		440300886: '纪元（南山）分院',
		440300887: '华融（慈铭）分院',
		440300888: '逸康（龙华北站）分院',
		440300943: '深圳瑞慈南山体检中心',
		440300974: '爱康国宾深圳罗湖分院',
		440300975: '爱康国宾深圳华强分院',
		440400738: '珠海分院',
		440600292: '美年大健康佛山分院',
		440600293: '美年大健康佛山季华园分院',
		440600294: '美年大健康佛山顺德分院',
		440600944: '佛山瑞慈禅城体检中心',
		440700739: '江门分院',
		440800392: '美年大健康湛江分院',
		440800393: '美年大健康湛江华都汇分院',
		441200396: '美年大健康肇庆慈铭分院',
		441200740: '肇庆分院',
		441300140: '美年大健康惠州分院',
		441300741: '惠州分院',
		441400341: '美年大健康梅州分院',
		441600134: '美年大健康河源分院',
		441600742: '河源分院',
		441700743: '阳江分院',
		441900112: '美年大健康东莞南城分院',
		441900113: '美年大健康东莞虎门分公司',
		441900114: '美年大健康东莞市松山湖分院',
		441900744: '黄金花园分院',
		441900745: '松山湖分院',
		442000260: '美年大健康中山分院',
		442000746: '慈铭火炬分院',
		445300390: '美年大健康云浮分院',
		445300747: '云浮分院',
		450100170: '美年大健康南宁高新分院',
		450100171: '美年大健康南宁江南分院',
		450100172: '美年大健康南宁琅东分院',
		450100748: '江南分院',
		450100749: '琅东分院',
		450200332: '美年大健康柳州分院',
		450200750: '城中分院',
		450300303: '美年大健康桂林甲天下广场分院',
		450300304: '美年大健康桂林临桂分院',
		450300751: '金水湾分院',
		450300752: '七星分院',
		450500270: '美年大健康北海分院',
		450500753: '北海分院',
		450700347: '美年大健康钦州分院',
		450700754: '钦州分院',
		450900389: '美年大健康玉林分院',
		450900755: '玉林分院',
		460100121: '美年大健康海口二分院',
		460100756: '奥亚分院',
		460100757: '海口分院',
		460100758: '海瑞分院',
		460400289: '美年大健康儋州分院',
		460400759: '儋州分院',
		500100261: '美年大健康重庆万象城分院',
		500100262: '美年大健康重庆江北分院',
		500100263: '美年大健康重庆石桥广场分院',
		500100399: '美年大健康重庆巴南分院',
		500100400: '美年大健康重庆万州分院',
		500100401: '美年大健康重庆涪陵分院',
		500100402: '美年大健康重庆江北嘴分院',
		500100403: '美年大健康重庆江南分院',
		500100760: '江北嘴分院',
		500100761: '重庆江南分院',
		500100762: '江北分院',
		500100763: '巴南分院',
		500100764: '九龙坡万象城分院',
		500100988: '爱康国宾重庆黄泥磅体检分院',
		510100102: '美年大健康成都武侯分院',
		510100103: '美年大健康成都维康分院',
		510100104: '美年大健康成都金牛分院',
		510100105: '美年大健康成都科华分院',
		510100106: '美年大健康成都青羊分院',
		510100107: '美年大健康成都凯尔分院',
		510100108: '美年大健康成都天府三街分院',
		510100278: '美年大健康成都龙泉分院',
		510100279: '美年大健康成都都江堰分院',
		510100280: '美年大健康成都温江分院',
		510100281: '美年大健康成都郫都分院',
		510100282: '美年大健康成都新都分院',
		510100283: '美年大健康成都青白江分院',
		510100284: '美年大健康成都邛崃分院',
		510100285: '美年大健康成都双流分院',
		510100286: '美年大健康成都金牛万达分院',
		510100765: '奥亚分院',
		510100766: '高新二分院',
		510100767: '金牛分院',
		510100768: '武侯分院',
		510100769: '高新分院',
		510100770: '星辉分院',
		510100771: '维康分院',
		510100772: '凯尔分院',
		510100773: '新都分院',
		510100774: '科华分院',
		510100775: '金牛万达分院',
		510100776: '青羊分院',
		510100777: '邛崃分院',
		510100778: '都江堰分院',
		510100779: '简阳分院',
		510100889: '金沙分院',
		510100890: '高新分院',
		510100945: '成都瑞慈锦江体检中心',
		510100946: '成都瑞慈温江体检中心',
		510100984: '成都红照壁航天科技分院',
		510100985: '爱康国宾成都城南分院',
		510100986: '爱康国宾成都高新分院',
		510100987: '爱康国宾成都骡马市分院',
		510300266: '美年大健康自贡分院',
		510300780: '自贡分院',
		510400345: '美年大健康攀枝花分院',
		510400781: '攀枝花分院',
		510500337: '美年大健康泸州二分院',
		510500782: '泸州分院',
		510500783: '泸州美年大健康',
		510600111: '美年大健康德阳分院',
		510600784: '德阳分院',
		510700162: '美年大健康绵阳分院',
		510700785: '绵阳分院',
		510800118: '美年大健康广元分院',
		510800786: '广元分院',
		510900199: '美年大健康遂宁分院',
		510900787: '遂宁分院',
		510900947: '四川遂宁瑞慈体检中心',
		511000788: '内江分院',
		511000948: '四川内江瑞慈体检中心',
		511100153: '美年大健康乐山分院',
		511100789: '乐山分院',
		511300790: '高坪分院',
		511300791: '嘉陵分院',
		511400792: '仁寿分院',
		511400793: '眉山美年大健康',
		511500386: '美年大健康宜宾临港分院',
		511500794: '宜宾分院',
		511700288: '美年大健康达州分院',
		511700795: '达州分院',
		511800382: '美年大健康雅安分院',
		511800384: '美年大健康延边白山分院',
		511800796: '雅安分院',
		512000405: '美年大健康资阳分院',
		512000797: '资阳分院',
		520100119: '美年大健康贵阳分院',
		520100798: '康源分院',
		520100799: '金融城分院',
		520200158: '美年大健康六盘水分院',
		520200800: '六盘水分院',
		520300801: '遵义分院',
		520400802: '安顺分院',
		520500272: '美年大健康毕节分院',
		520500803: '毕节分院',
		522300804: '兴义分院',
		522600805: '凯里分院',
		522600806: '凯里慈铭',
		522700807: '都匀分院',
		530100148: '美年大健康昆明广福分院',
		530100149: '美年大健康昆明国贸分院',
		530100150: '美年大健康昆明高新分院',
		530300348: '美年大健康曲靖分院',
		530300808: '曲靖分院',
		530400809: '玉溪分院',
		530500456: '美年大健康保山分院',
		530500810: '保山分院',
		530800811: '普洱分院',
		530900812: '临沧分院',
		532300813: '楚雄分院',
		532500308: '美年大健康红河分院',
		532500814: '红河分院',
		532600815: '文山分院',
		532800816: '西双版纳分院',
		540100817: '拉萨分院',
		610100231: '美年大健康西安未央分院',
		610100232: '美年大健康西安建国分院',
		610100233: '美年大健康西安曲江分院',
		610100234: '美年大健康西安高新分院',
		610100235: '美年大健康西安科技路分院',
		610100818: '建国分院',
		610100819: '南门分院',
		610100820: '高新分院',
		610100821: '未央分院',
		610700306: '美年大健康汉中分院',
		610700822: '汉中分院',
		620100152: '美年大健康兰州金茂分院',
		620100823: '奥亚分院',
		620100824: '金城分院',
		620100825: '金茂分院',
		620400410: '美年大健康白银分院',
		620600372: '美年大健康武威分院',
		620800826: '平凉分院',
		620900321: '美年大健康酒泉分院',
		620900827: '酒泉分院',
		621000181: '美年大健康庆阳分院',
		621000828: '金阳分院',
		621200335: '美年大健康陇南分院',
		630100237: '美年大健康西宁分院',
		630100829: '西宁分院',
		640100388: '美年大健康银川分院',
		640400296: '美年大健康固原分院',
		640400830: '固原分院',
		650100217: '美年大健康乌鲁木齐水区分院',
		650100218: '美年大健康乌鲁木齐高新分院',
		650100219: '美年大健康乌鲁木齐沙区分院',
		650100831: '昆仑分院',
		650100832: '沙区分院',
		650100833: '乌鲁木齐高新分院',
		650100891: '经济开发区',
		650500120: '美年大健康哈密分院',
		650500834: '哈密分院',
		652900455: '美年大健康阿克苏分院',
		652900836: '阿克苏分院',
		653100837: '喀什分院',
		654000245: '美年大健康伊犁分院',
		654000838: '伊犁分院',
		1101001115: '博济体检北京分院',
		1101001173: '海医汇国贸店',
		1101001174: '北京瑞程医院管理有限公司第二口腔门诊部（瑞泰齿科双井店）',
		1101001175: '瑞泰齿科望京店',
		1101001176: '北京瑞程医院管理有限公司瑞泰口腔医院(瑞泰齿科北苑店)',
		1101001177: '北京佳和口腔门诊部',
		1101001178: '北京健翔口腔门诊部',
		1101001179: '北京启康昊城口腔门诊部',
		1101001180: '北京瑞程医院管理有限公司第三口腔诊所（瑞泰齿科亦庄店）',
		1101001181: '北京瑞程医院管理有限公司第六口腔门诊部（瑞泰齿科工体店）',
		1101001182: '北京瑞程医院管理有限公司第四口腔门诊部（瑞泰齿科上地店）',
		1101001183: '北京牙管家口腔诊所',
		1101001184: '瑞泰齿科陶然亭店',
		1101001185: '北京悦牙口腔（大兴顺康店）',
		1101001186: '北京晴雪园雅德嘉口腔门诊部',
		1101001187: '北京瑞程医院管理有限公司（瑞泰齿科丰台分院）',
		1201001078: '河西分院',
		1201001079: '河东分院',
		1201001080: '华苑分院',
		1201001128: '天津-华仁医院',
		1201001776: '天津河西区优诺佳口腔门诊部',
		1301001329: '栾城固德口腔门诊部',
		1301001330: '石家庄德贝口腔门诊部',
		1301001331: '桥西刘树涛口腔诊所',
		1301001332: '桥西区王曼英口腔诊所',
		1301001333: '石家庄和协口腔医院',
		1301001334: '石家庄玉芝口腔门诊部',
		1301001335: '石家庄市裕华区康民口腔诊所',
		1301001336: '裕华重光康民口腔诊所',
		1301001337: '石家庄牙博士口腔医院',
		1301001340: '桥西雅洁口腔诊所',
		1301001343: '石家庄牙博士口腔医院管理有限公司新华青云口腔门诊部',
		1301001346: '石家庄美年美华口腔医疗管理有限公司桥西口腔门诊部',
		1301001347: '石家庄和睦健康管理咨询有限公司裕华口腔诊所',
		1301001348: '石家庄和睦健康管理咨询有限公司长安口腔诊所',
		1302001338: '迁安精典口腔诊所',
		1302001345: '唐山牙博士优牙口腔门诊部',
		1303001328: '秦皇岛中易口腔医疗有限公司第一诊所',
		1303001344: '北戴河新区贝齿口腔诊所',
		1304001103: '仁泰体检邯郸东区',
		1304001104: '仁泰体检邯郸西区',
		1304001105: '仁泰体检邯郸北区',
		1304001321: '郝光信口腔诊所',
		1304001322: '焦斌口腔(焦学斌口腔诊所)',
		1304001323: '众欢口腔诊所',
		1305001106: '邢台仁泰体检',
		1305001339: '程浩亮口腔诊所',
		1306001119: '慈惠保定分院',
		1306001319: '保定白沟新城圣恩口腔诊所',
		1306001320: '保定市竞秀区思美口腔诊所',
		1308001120: '慈惠承德分院',
		1310001325: '廊坊爱嘉口腔门诊部',
		1310001326: '三河束贝紫丁香口腔门诊部',
		1310001327: '香河王建华口腔诊所',
		1310001341: '三河阳光树口腔门诊部',
		1310001342: '三河编贝紫丁香口腔门诊部',
		1311001114: '博爱体检衡水分院',
		1311001324: '桃城瑞尔口腔诊所',
		1401001124: '太原分院',
		1401001139: '太原分院',
		1401001696: '太原幸福里口腔（孟繁涛口腔诊所）',
		1401001697: '太原小店博凡口腔门诊部',
		1401001698: '太原吴红瑞口腔诊所',
		1401001699: '山西盛大齿科医院',
		1401001700: '太原市迎泽区白翔口腔诊所',
		1401001707: '太原市小店区美齿诊所',
		1401001708: '太原摩尔口腔门诊部',
		1401001709: '太原恒月口腔门诊部',
		1403001701: '现代齿科(吴志会口腔诊所)',
		1404001703: '长治亿大口腔医院',
		1404001704: '长治和谐口腔医院',
		1404001705: '长治长兴口腔医院',
		1404001706: '长治众雅口腔门诊部',
		1406001695: '柳振华口腔诊所',
		1407001692: '灵石县董慧斌口腔诊所',
		1407001693: '博尔口腔门诊部',
		1408001702: '克明口腔诊所',
		1410001138: '临汾分院',
		1410001694: '洪洞红十字（乐德斯）口腔门诊部',
		1501001142: '泽康体检',
		1501001602: '呼和浩特亚非口腔门诊部',
		1501001603: '至美口腔门诊部',
		1502001143: '泽康体检',
		1502001578: '包头麒麟口腔医院',
		1502001579: '九原区王鑫口腔诊所',
		1502001580: '昆区菅海宽口腔诊所',
		1502001581: '包头市贝特瑞尔口腔有限公司第四分公司',
		1502001582: '稀土高新区义隆牙博士口腔门诊部',
		1502001583: '青山凯斯口腔门诊部',
		1502001584: '青山区植诺口腔门诊部',
		1502001606: '东河区史伟口腔诊所',
		1503001604: '吕卓口腔诊所',
		1504001585: '赤峰市德医美口腔医院',
		1506001586: '鄂尔多斯市青蕾口腔医疗有限公司口腔诊所',
		1506001587: '东胜区尹美英口腔诊所',
		1506001588: '东胜区志强口腔诊所',
		1506001589: '鄂尔多斯汉生口腔医院',
		1506001590: '鄂尔多斯市奥麒口腔医院',
		1506001591: '内蒙古汉林口腔种植医院有限公司口腔诊所分公司',
		1506001592: '奇布拉格口腔诊所',
		1506001593: '王娜口腔诊所',
		1506001594: '殷慧文口腔病防治所',
		1506001595: '奥麒口腔医院康巴什门诊部',
		1506001596: '同心口腔门诊部',
		1506001597: '鄂尔多斯市伊金霍洛旗潘慧聪口腔诊所',
		1506001598: '鄂尔多斯伊金霍洛旗高鹃口腔诊所',
		1506001599: '伊金霍洛旗阿镇杭氏口腔门诊部',
		1506001600: '伊金霍洛旗阿镇惠文口腔病防治所',
		1506001601: '伊金霍洛旗牙之家口腔门诊部',
		1506001605: '内蒙古惠文口腔门诊部',
		1508001099: '巴彦淖尔分院',
		1508001112: '巴彦淖尔体检',
		2101001006: '沈阳爱康国宾医院太原街分院',
		2101001007: '沈阳爱康国宾青年大街分院',
		2101001019: '沈阳熙康健康管理中心',
		2101001020: '沈阳浑南熙康医院',
		2101001021: '盛京熙康健康管理中心沈阳世茂分院',
		2101001126: '华领-沈阳分院',
		2101001547: '沈阳大东全好百乐口腔门诊部有限公司（全好沈阳百乐）',
		2101001548: '沈阳全好口腔医疗门诊服务有限公司大东东顺口腔门诊部（全好沈阳东顺）',
		2101001549: '沈阳全好口腔医疗门诊服务有限公司大东吉祥口腔门诊部（全好沈阳吉祥）',
		2101001550: '沈阳全好口腔医疗门诊服务有限公司大东联合路口腔门诊部（全好沈阳联合路）',
		2101001551: '沈阳全好口腔医疗门诊服务有限公司大东桃原口腔门诊部（全好沈阳桃原）',
		2101001552: '沈阳大东北海沈美口腔门诊部',
		2101001553: '沈阳大东东顺沈美口腔门诊部',
		2101001554: '沈阳大东江东沈美口腔门诊部',
		2101001555: '沈阳欢乐英卓医院管理有限公司和平仁爱门诊部',
		2101001556: '沈阳全好口腔医疗门诊服务有限公司和平三好街口腔门诊部（全好沈阳三好街）',
		2101001557: '沈阳全好口腔医疗门诊服务有限公司太原南街口腔门诊部（全好沈阳太原南街）',
		2101001558: '沈阳和平经纬沈美口腔门诊部',
		2101001559: '沈阳贝斯特医院管理有限公司皇姑珠江街口腔门诊部',
		2101001560: '沈阳全好口腔医疗门诊服务有限公司昆山西路口腔门诊部（全好沈阳昆山西路）',
		2101001561: '沈阳欢乐英卓医院管理有限公司浑南欢乐仁爱口腔门诊部',
		2101001562: '沈阳欢乐英卓医院管理有限公司沈河欢乐仁爱口腔医院',
		2101001563: '沈阳欢乐英卓医院管理有限公司沈河十一纬路仁爱口腔门诊部',
		2101001564: '沈阳全好口腔医疗门诊服务有限公司沈河奉天街口腔门诊部（全好沈阳奉天街）',
		2101001565: '沈阳全好口腔医疗门诊服务有限公司沈河万寿寺口腔门诊部（全好沈阳万寿寺）',
		2101001566: '沈阳沈河顺城沈美口腔门诊部',
		2101001567: '沈阳沈河小西沈美口腔门诊部',
		2101001568: '沈阳沈河顺通口腔门诊部',
		2101001569: '沈阳全好口腔医疗门诊服务有限公司铁西保工口腔门诊部（全好沈阳保工）',
		2101001570: '全好沈阳铁西兴顺',
		2101001571: '沈阳全好口腔医疗门诊服务有限公司铁西艳粉口腔门诊部（全好沈阳艳粉）',
		2101001572: '沈阳全好口腔医疗门诊服务有限公司铁西艳欣口腔门诊部（全好沈阳艳欣）',
		2101001573: '沈阳全好口腔医疗门诊服务有限公司铁西云峰口腔门诊部（全好沈阳云峰）',
		2101001574: '沈阳铁西全好贵佳和口腔门诊部有限公司（全好沈阳贵佳和）',
		2101001575: '沈阳英卓医院管理有限公司新民欢乐仁爱口腔门诊部',
		2102001022: '大连东软熙康健管中心',
		2102001519: '大连甘井子红旗东路全好口腔门诊部有限公司富强路口腔诊所（全好大连富强路）',
		2102001520: '大连甘井子红旗东路全好口腔门诊部有限公司千山路口腔诊所（全好大连千山路）',
		2102001521: '大连甘井子红旗东路全好口腔门诊部有限公司湾家口腔诊所（全好大连湾家）',
		2102001522: '大连甘井子红旗东路全好口腔门诊部有限公司文体街口腔诊所（全好大连文体街）',
		2102001523: '大连甘井子红旗东路全好口腔门诊部有限公司辛吉街口腔诊所（全好大连辛吉街）',
		2102001524: '大连甘井子红旗东路全好口腔门诊部有限公司迎客路口腔诊所（全好大连迎客路）',
		2102001525: '大连甘井子红旗东路全好口腔门诊部有限公司（全好大连红旗东路）',
		2102001526: '大连沙河口区锦绣路全好口腔门诊有限公司（全好大连锦绣路）',
		2102001527: '大连沙河口区西南路全好口腔门诊有限公司（全好大连西南路）',
		2102001528: '大连沙河口区中山公园全好口腔门诊有限公司（全好大连中山公园）',
		2102001529: '大连沙河口区中山公园全好口腔门诊有限公司北甸街口腔诊所（全好大连北甸街）',
		2102001530: '大连沙河口区中山公园全好口腔门诊有限公司香沙街口腔诊所（全好大连香沙街）',
		2102001531: '大连沙河口区中山公园全好口腔门诊有限公司星海口腔诊所（全好大连星海）',
		2102001532: '大连沙河口区中山公园全好口腔门诊有限公司兴工街口腔诊所（全好大连兴工街）',
		2102001533: '大连西岗区黄河路全好口腔门诊有限公司（全好大连黄河路）',
		2102001534: '大连全好口腔门诊有限公司（全好大连）',
		2102001535: '大连全好口腔门诊有限公司丹东街口腔诊所（全好大连丹东街）',
		2102001536: '大连全好口腔门诊有限公司解放路口腔诊所（全好大连解放路）',
		2102001537: '大连全好口腔门诊有限公司山屏街口腔诊所（全好大连屏街）',
		2102001538: '大连全好医疗门诊服务有限公司七贤路口腔诊所（全好大连七贤路）',
		2104001542: '抚顺市顺城区顺成悦美口腔门诊部',
		2105001518: '桓仁田锋口腔门诊部',
		2106001023: '丹东金海熙康健康管理中心',
		2106001024: '熙康健康管理中心丹东分院',
		2106001097: '丹东分院',
		2106001539: '东港高淑芳口腔诊所',
		2106001540: '丹东市元宝区王艳华口腔诊所',
		2106001541: '丹东唯美医疗服务有限公司中富口腔诊所',
		2108001137: '谂达康体检',
		2108001576: '杨秋玲口腔个体诊所',
		2108001577: '盖州奥新全民口腔医院',
		2110001544: '刘艳口腔诊所',
		2111001111: '爱华体检',
		2111001545: '兴隆台奥泰口腔门诊部',
		2111001546: '盘锦市兴隆台区包稳口腔诊所',
		2114001543: '葫芦岛市龙港区骏辉口腔诊所',
		2201001004: '爱康国宾长春亚泰鸿城西域体检分院',
		2201001005: '爱康国宾长春建设分院',
		2201001125: '华领-长春分院',
		2202001458: '吉林磐石包大伟牙科医院',
		2205001460: '通化市东昌区金博康口腔门诊部',
		2206001457: '白山市浑江区雅康口腔诊所',
		2207001459: '松原精技口腔门诊部',
		2301001090: '哈尔冰分院',
		2301001393: '哈尔滨卓尔口腔门诊部',
		2301001394: '哈尔滨啄木鸟口腔门诊部',
		2301001397: '哈尔滨拜博口腔（和平路门诊）',
		2301001398: '哈尔滨市南岗区金爵口腔门诊部',
		2301001399: '哈尔滨德诚明晋口腔门诊部',
		2302001395: '齐齐哈尔守平口腔医院',
		2302001396: '铁锋区鹤翔口腔门诊部',
		2306001390: '圣光口腔门诊部',
		2306001391: '大庆市萨尔图区优根口腔门诊部',
		2306001392: '丽花口腔门诊部',
		2306001400: '大庆市晨光口腔门诊部',
		2308001098: '佳木斯分院',
		3101001025: '上海熙康健康管理有限公司',
		3101001026: '熙康健康管理中心上海张江分院',
		3101001734: '上海罗缘口腔门诊部',
		3101001735: '上海尚雅口腔门诊部',
		3101001736: '上海皇欣口腔门诊部',
		3101001737: '瑞泰口腔(前滩旗舰店)',
		3101001738: '上海雅适美口腔门诊部',
		3101001739: '上海艾维口腔门诊部',
		3101001740: '上海佳沪口腔门诊部',
		3101001741: '上海圣康口腔门诊部',
		3101001742: '上海美乐口腔门诊部',
		3101001743: '上海百仕口腔门诊部',
		3101001744: '上海宇佳口腔门诊部',
		3101001745: '上海瑞泰永源口腔门诊部',
		3201001466: '南京六合值德口腔门诊部',
		3201001487: '南京玉蕙口腔门诊部',
		3201001495: '南京贝鹤雅康口腔医院',
		3202001478: '(金贝齐齿科)无锡贝齐口腔门诊部',
		3202001479: '无锡爱牙口腔门诊部',
		3202001480: '无锡雅仕康口腔门诊部',
		3202001481: '(金贝齐齿科)无锡金汇口腔门诊部',
		3202001488: '江阴弘和口腔门诊部',
		3203001493: '徐州泉山博奥口腔门诊部',
		3204001461: '常州贺冰口腔诊所',
		3204001462: '常州铭齿口腔门诊部',
		3204001463: '常州鑫镁口腔门诊部',
		3204001490: '常州市东太平洋口腔门诊部',
		3204001491: '常州太平洋口腔门诊部',
		3204001492: '常州南部太平洋口腔门诊部',
		3205001082: '微检-苏州分院',
		3205001472: '昆山瑞德口腔门诊部',
		3205001473: '昆山市玉山镇嘉丽口腔门诊部',
		3205001474: '太仓益贝佳口腔门诊部',
		3205001475: '苏州工业园区舒迪凯口腔诊所',
		3205001494: '张家港玉蕙口腔医院',
		3205001496: '昆山益达口腔门诊部',
		3205001497: '姑苏区韩欣义口腔诊所',
		3205001498: '张家港皓康口腔门诊部',
		3206001467: '南通玉蕙口腔医院',
		3206001468: '海门思迈尔口腔门诊部',
		3206001469: '啄木鸟口腔门诊部',
		3206001470: '如皋牙博士口腔门诊部',
		3206001471: '通州牙卫士口腔门诊部',
		3207001465: '连云港美年口腔',
		3208001464: '雅美口腔门诊部',
		3209001483: '射阳兴华口腔门诊部',
		3209001484: '盐城倍美永康口腔门诊有限公司倍美永康口腔门诊部',
		3209001489: '盐城通植口腔门诊部',
		3209001499: '盐城安佳倍美口腔门诊部',
		3210001485: '高邮新时代口腔门诊部',
		3210001486: '江都区仙女镇前程口腔门诊部',
		3212001476: '泰州北极星口腔门诊部',
		3212001477: '玲珑口腔门诊部',
		3213001482: '泗阳县口腔医院',
		3301001059: '杭州公司-深泰分院',
		3301001796: '品凯口腔彭埠门诊',
		3301001797: '品凯口腔明月门诊',
		3301001798: '品凯口腔固陵门诊',
		3301001799: '品凯口腔拱北门诊',
		3301001800: '品凯口腔登云门诊',
		3301001801: '品凯口腔祥符门诊',
		3301001802: '杭州全好健康管理有限公司湖墅南路口腔诊所（全好杭州湖墅南路）',
		3301001803: '杭州全好健康管理有限公司信义坊口腔诊所（全好杭州信义坊）',
		3301001804: '杭州同好健康管理有限公司（全好杭州同好口腔）',
		3301001805: '品凯口腔丁桥门诊',
		3301001806: '品凯口腔博皓门诊',
		3301001807: '品凯口腔凤起门诊',
		3301001808: '杭州登丝特口腔门诊部',
		3301001809: '杭州云齿口腔医院',
		3301001810: '品凯口腔盈佳门诊',
		3301001811: '杭州拜博口腔（德力西门诊）',
		3301001812: '品凯口腔晴川门诊',
		3301001813: '品凯口腔丰潭门诊',
		3301001814: '杭州全好健康管理有限公司莲花街口腔诊所（全好杭州莲花街）',
		3301001815: '杭州全好健康管理有限公司学院路口腔诊所（全好杭州学院路）',
		3301001816: '杭州市西湖区全好口腔门诊部（全好杭州西湖）',
		3301001817: '杭州微牙口腔门诊部',
		3301001818: '品凯口腔华丰门诊',
		3301001819: '品凯口腔旗舰总部',
		3301001820: '杭州植得口腔门诊部',
		3301001821: '品凯口腔品洁门诊',
		3301001822: '杭州余杭非凡口腔门诊部',
		3301001823: '杭州牙安口腔医疗管理有限公司余杭东湖中路口腔诊所',
		3301001835: '杭州德贝艾尔口腔门诊部',
		3301001837: '杭州贝齿特微笑口腔诊所',
		3301001838: '品凯口腔东站数字化门诊',
		3301001839: '品凯口腔桃源门诊',
		3301001840: '杭州城星美奥口腔门诊部',
		3301001846: '品凯口腔亮贝门诊',
		3301001847: '杭州萧山匠心德贝金境口腔门诊部',
		3302001027: '宁波云医院',
		3302001826: '宁波鄞州协禾口腔门诊部',
		3302001827: '宁波鄞州牙科医院',
		3303001833: '李永玮口腔诊所',
		3303001841: '温州奥齿泰口腔门诊部',
		3303001843: '温州瓯海爱牙故事口腔门诊部',
		3303001844: '温州鹿城爱牙故事口腔门诊部',
		3303001845: '温州鹿城爱牙故事新城口腔门诊部',
		3304001824: '平湖市复尔口腔门诊部',
		3306001828: '绍兴越中口腔医院',
		3307001836: '武义微笑口腔诊所',
		3307001842: '义乌百奥口腔门诊部',
		3309001834: '普陀维乐口腔门诊部',
		3310001829: '临海市汤歆影口腔诊所',
		3310001830: '天台余家口腔门诊部',
		3310001831: '温岭泽国安然口腔门诊部',
		3310001832: '仙居县博雅口腔门诊部',
		3311001825: '龙泉新华口腔诊所',
		3401001028: '安徽熙康健康管理有限公司',
		3401001029: '熙康健康管理中心合肥望江路分院',
		3401001161: '合肥经开区现代口腔门诊部（朱小龙连锁）',
		3401001162: '佳德口腔包河分院',
		3401001163: '佳德口腔肥西分院',
		3401001170: '合肥靓美口腔医院（总院）',
		3401001171: '合肥靓美口腔门诊部（经开店）',
		3403001169: '爱牙乐口腔门诊部（大学城店）',
		3405001166: '马鞍山鑫马口腔医院',
		3408001145: '桐城亚安口腔门诊部',
		3408001146: '安庆朱小龙口腔医院',
		3408001147: '安庆朱小龙口腔医院光彩门诊部',
		3408001148: '安庆贝尔口腔门诊部',
		3408001149: '安庆燚燊综合门诊部',
		3408001150: '安庆朱小龙口腔医院东部院区',
		3408001151: '安庆朱小龙口腔医院菱湖门诊部',
		3411001113: '滁州分院',
		3411001172: '金陵口腔门诊部',
		3412001156: '阜阳爱齿口腔门诊部',
		3412001157: '阜阳贝德口腔门诊部',
		3412001158: '阜阳松华口腔门诊部',
		3412001159: '颍州爱齿口腔门诊部',
		3412001160: '颍州贝德口腔门诊部',
		3415001164: '六安悦牙口腔门诊部',
		3415001165: '六安竹子口腔医院',
		3416001152: '利辛爱齿口腔第二门诊部',
		3416001153: '利辛爱齿口腔门诊部',
		3416001154: '谯城小天使口腔门诊部',
		3416001168: '蒙城县贝德口腔医院',
		3417001155: '东至亚安口腔门诊部',
		3418001167: '徐岚口腔门诊部',
		3501001030: '福州分院',
		3501001031: '熙康健康管理中心福州分院',
		3501001084: '福州分院',
		3501001085: '长乐分院',
		3501001086: '福清分院',
		3501001087: '诚丰世纪园分院',
		3501001188: '晋安区三颗牙口腔门诊部',
		3503001196: '荔城金豆芽口腔门诊部',
		3504001192: '博悦口腔门诊部',
		3504001193: '三明梅列十博悦口腔门诊部',
		3505001189: '泉州丰泽维乐口腔门诊',
		3505001190: '石狮李金标口腔门诊部',
		3505001191: '石狮市金恩美口腔门诊部',
		3505001194: '泉州丰泽维乐云贝店',
		3505001195: '泉州维乐口腔晋江万达店',
		3505001197: '晋江青阳贝成口腔门诊部',
		3506001089: '漳州分院',
		3601001133: '南昌分院',
		3601001506: '高新尚吉慧口腔门诊部',
		3601001507: '南昌美伦口腔门诊部',
		3602001503: '昌江区亚齐口腔门诊部',
		3603001508: '安源区修安口腔门诊部',
		3603001509: '安源修安口腔门诊部金典城分部',
		3603001510: '安源好牙一生口腔诊所',
		3604001504: '九江市奇洋口腔门诊部（普通合伙）',
		3604001505: '浔阳区奇洋庐峰口腔门诊部（普通合伙）',
		3605001514: '新余市章根训口腔医学中心',
		3606001516: '江平军口腔诊所',
		3606001517: '江西省鹰潭市尚品口腔门诊部',
		3607001096: '仁心体检-赣州分院',
		3607001131: '康俪医院',
		3607001501: '赣州市管江口腔门诊部',
		3607001502: '赣州阳光口腔门诊部',
		3609001091: '宜春分院',
		3609001515: '宜春辉荣口腔门诊部',
		3610001092: '抚州分院',
		3610001500: '抚州百合口腔门诊部',
		3611001511: '广信美唯阳光口腔门诊部',
		3611001512: '上饶市维乐阳光口腔医院',
		3611001513: '上饶信州美维阳光口腔门诊部',
		3701001616: '济南可恩口腔医院和谐广场分院',
		3701001617: '莱芜牙博士口腔清水湾门诊部',
		3701001618: '莱芜牙博士口腔胜利路门诊部',
		3701001619: '莱芜景先军口腔诊所',
		3701001620: '济南可恩口腔医院',
		3701001621: '历城路业舜天口腔诊所',
		3701001622: '历下毅卉口腔门诊部',
		3701001623: '济南可恩口腔国际会展门诊部',
		3701001624: '济南可恩口腔医院历下门诊部',
		3701001625: '济南全好健康管理有限公司历下朝山街口腔诊所（全好济南朝山街）',
		3701001626: '济南全好健康管理有限公司历下东关大街口腔门诊部（全好济南东关大街）',
		3701001627: '高新雅辰口腔门诊部',
		3701001628: '市中万康口腔门诊部',
		3701001680: '济南亿佳医疗管理有限公司历城华龙路口腔诊所',
		3701001686: '济南高新晨阳口腔门诊部',
		3702001072: '新华青岛健康管理中心',
		3702001073: '中康体检开发区中心',
		3702001074: '中康体检新黄岛区中心',
		3702001075: '中康体检城阳区中心',
		3702001076: '中康体检胶州市中心',
		3702001077: '中康体检市南区中心',
		3702001642: '城阳徐环环雅新口腔诊所',
		3702001643: '即墨瑞康口腔门诊部',
		3702001644: '即墨顺康口腔门诊部',
		3702001645: '市北雅乐康口腔诊所',
		3702001646: '青岛可恩口腔李沧院',
		3702001647: '青岛全好健康管理有限公司李沧区巨峰路口腔门诊部（全好青岛巨峰路）',
		3702001648: '青岛全好健康管理有限公司李沧区南崂路口腔门诊部（全好青岛南崂路）',
		3702001649: '青岛全好健康管理有限公司李沧区夏庄路口腔门诊部（全好青岛夏庄路）',
		3702001650: '青岛全好健康管理有限公司李沧区振华路口腔门诊部（全好青岛振华路）',
		3702001651: '李沧牙博士口腔门诊部',
		3702001652: '李沧百佳口腔诊所',
		3702001653: '青岛可恩口腔医院市北分院',
		3702001654: '青岛全好健康管理有限公司市北区辽宁路口腔门诊部（全好青岛辽宁路）',
		3702001655: '青岛全好健康管理有限公司市北区延吉路口腔诊所（全好青岛延吉路）',
		3702001656: '青岛全好健康管理有限公司市北区长春路口腔诊所（全好青岛长春路）',
		3702001657: '青岛牙博士口腔医院',
		3702001658: '市北优家口腔门诊部',
		3702001659: '青岛可恩口腔医院市南分院',
		3702001660: '青岛全好健康管理有限公司市南区江西路口腔门诊部（全好青岛江西路）',
		3702001679: '青岛和爱慈医疗管理有限公司市北艾诺口腔诊所',
		3702001683: '青岛瑞齿口腔门诊部',
		3703001675: '淄博高青瑞佳口腔医院',
		3703001676: '山东金良口腔诊所（华光路店）',
		3704001681: '滕州万康口腔门诊部',
		3704001685: '枣庄市澳美口腔',
		3705001613: '东营同泰口腔医院',
		3705001614: '晓倩口腔诊所',
		3705001615: '金伟牙科诊所',
		3706001000: '爱康国宾烟台芝罘区南大街体检分院',
		3706001674: '龙口陈学刚口腔诊所',
		3706001684: '烟台爱博口腔门诊部',
		3706001689: '蓬莱美达口腔门诊部',
		3707001001: '潍坊新华路分院',
		3707001002: '潍坊东方路分院',
		3707001666: '高密丁伟口腔诊所',
		3707001667: '潍坊坊子宏垣口腔医院',
		3707001668: '郑氏口腔坊子店（坊子致雅口腔门诊部）',
		3707001669: '郑氏口腔医院',
		3707001670: '郑氏口腔四平路店（奎文邢轲口腔诊所）',
		3707001671: '青州东圣水医院',
		3707001672: '郑氏口腔南柴店（寿光井旭口腔诊所）',
		3707001673: '郑氏口腔建新街口腔诊所',
		3707001687: '郑氏口腔潍城店（潍城登特口腔门诊部）',
		3708001629: '任城李凤华口腔科诊所',
		3708001630: '王琦口腔门诊部',
		3708001631: '汶上德柱口腔门诊部',
		3709001662: '泰安市泰山区洪元口腔诊所',
		3709001663: '泰安雅玉口腔医院',
		3709001664: '新泰口腔医院',
		3710001003: '威海滨海分院',
		3710001665: '威海舒好口腔门诊部',
		3710001688: '环翠区李欢口腔诊所',
		3711001661: '东港世同口腔门诊部',
		3713001640: '临沂兰山晓波口腔门诊部',
		3713001641: '临沂刘子斌口腔医疗有限公司口腔门诊部',
		3713001682: '临沂优贝口腔医疗有限公司口腔门诊部',
		3713001691: '临沂兰山银座牙客口腔门诊部',
		3714001101: '德州分院',
		3714001611: '德州康韵口腔诊所',
		3714001612: '禹城市孙建涛口腔诊所',
		3715001632: '聊城口腔医院',
		3715001633: '聊城恒丰口腔诊所',
		3715001634: '聊城惠尔口腔门诊部',
		3715001635: '众望口腔诊所',
		3715001636: '茌平颐正口腔医院',
		3715001637: '聊城美冠口腔',
		3715001638: '聊城临清市鼎正口腔',
		3715001639: '吴清华口腔诊所',
		3715001677: '东阿县新城颐正口腔门诊部',
		3715001690: '聊城颐正口腔门诊部',
		3716001609: '滨城曲站峰口腔诊所',
		3716001610: '滨城洁仕口腔门诊部',
		3716001678: '惠民雅之美口腔诊所',
		4101001081: '豫慈体检-郑州分院',
		4101001371: '金水爱尔雅口腔门诊部',
		4101001372: '郑东康庄路天美口腔门诊部',
		4101001374: '二七碧云赛雯口腔门诊部',
		4101001376: '二七盛禾口腔门诊部',
		4102001350: '开封新区刘文利口腔诊所',
		4103001351: '涧西维乐口腔门诊部',
		4103001352: '洛阳维乐口腔（沃德店）',
		4103001353: '洛阳维乐口腔（凌宇犀地店）',
		4103001354: '洛阳维乐口腔（七一路店）',
		4103001355: '洛阳维乐口腔（春都路店）',
		4103001356: '金诺口腔门诊部',
		4103001357: '健康西路斯麦尔口腔门诊部',
		4103001375: '洛阳六和口腔医院',
		4103001377: '七一路九龙口腔门诊部',
		4103001378: '延安路九龙口腔门诊部',
		4103001379: '八一路九龙口腔门诊部',
		4103001380: '洛宁九龙口腔门诊部',
		4103001381: '洛宁九龙口腔诊所',
		4103001382: '永泰街九龙口腔门诊部',
		4103001383: '太康路九龙口腔门诊部',
		4103001384: '上阳路九龙口腔门诊部',
		4103001385: '浅井南路九龙口腔门诊部',
		4103001386: '瀍河九龙口腔门诊部',
		4103001387: '英才路九龙口腔门诊部',
		4103001388: '周山路九龙口腔门诊部',
		4103001389: '珠江路九龙口腔门诊部',
		4104001359: '湛河贝诺口腔门诊部',
		4105001349: '汤阴越峰门诊部',
		4107001365: '益尔口腔（新乡牧野岳晓玲口腔诊所）',
		4107001366: '长垣京典口腔',
		4108001083: '微检-焦作分院',
		4109001107: '濮阳仁泰体检',
		4109001360: '濮阳爱之牙口腔门诊部',
		4109001361: '濮阳光华口腔门诊部',
		4110001369: '许昌小叮当口腔门诊部（小叮当连锁）',
		4110001370: '长葛刘书攀口腔诊所（小叮当连锁）',
		4111001358: '舒适美口腔（源汇许克哲口腔诊所）',
		4112001362: '李秀春口腔诊所',
		4112001363: '三门峡泊菲特口腔门诊部',
		4114001364: '睢阳新城蓝天口腔门诊部',
		4115001367: '伢牙齿科口腔门诊部（平桥店）',
		4115001368: '伢牙齿科口腔门诊部（浉河店）',
		4117001373: '驿城区美齿口腔门诊部',
		4201001009: '武汉爱康国宾金墩分院',
		4201001032: '武汉京汉大智路分院',
		4201001033: '熙康健康管理中心武汉京汉大智路分院',
		4201001060: '武汉公司-武汉分院',
		4201001422: '武汉德韩摩尔城口腔门诊部',
		4201001423: '武汉良依口腔花桥门诊部',
		4201001424: '武汉德韩凯德西城口腔门诊部',
		4201001425: '武汉青山东华口腔门诊部',
		4201001426: '武汉皓诺口腔门诊部',
		4201001433: '朗歌口腔武昌店',
		4201001434: '朗歌口腔江汉店',
		4201001436: '武汉雅立德口腔门诊部',
		4203001420: '十堰市张中勇口腔门诊部',
		4205001093: '宜昌仁康体检',
		4205001429: '宜昌高新区景玺口腔诊所',
		4205001430: '宜昌高新区益齿生口腔门诊部',
		4205001431: '宜昌高新区汪万年东山口腔门诊部',
		4205001432: '宜昌市西陵区学院街汪万年口腔门诊部',
		4205001435: '宜昌市伍家岗区同仁口腔诊所',
		4206001428: '襄阳时代永恒口腔立业路门诊部',
		4208001415: '钟祥小灰兔口腔门诊',
		4208001416: '钟祥恩吉口腔门诊部',
		4208001417: '钟祥海峰口腔诊所',
		4210001418: '沙市区黄兵兵口腔诊所',
		4210001419: '沙市区荆楚固德口腔诊所',
		4211001413: '红安县思迈尔口腔门诊部',
		4211001414: '浠水美维中山口腔门诊部',
		4212001427: '佳龙口腔诊所',
		4213001421: '随州东华口腔门诊部',
		4228001401: '恩施咿呀口腔巴东诊所',
		4228001402: '恩施咿呀口腔福星诊所',
		4228001403: '恩施咿呀口腔舞阳诊所',
		4228001404: '恩施咿呀口腔金桂诊所',
		4228001405: '恩施咿呀口腔鹤峰诊所',
		4228001406: '恩施咿呀口腔建始诊所',
		4228001407: '恩施咿呀口腔来凤诊所',
		4228001408: '恩施咿呀口腔利川诊所',
		4228001409: '恩施咿呀口腔宣恩诊所',
		6401001008: '银川爱康国宾慈铭综合门诊部',
		4301001010: '长沙爱康国宾阳光分院',
		4301001011: '长沙爱康国宾八一桥分院',
		4301001012: '爱康国宾长沙河西西中心分院',
		6101001013: '西安爱康经开分院',
		6101001014: '西安爱康曲江分院',
		5201001015: '贵州爱康国宾韦顺达源分院',
		5107001016: '绵阳爱康高新火炬广场分院',
		5205001018: '爱康国宾毕节松山路三江花园分院',
		4301001034: '湖南仁术熙康健康管理中心',
		4301001035: '湖南仁术熙康健康管理中心',
		4601001036: '熙康健康管理中心海南分院',
		5101001037: '成都东软熙康健康管理服务有限公司-综合门诊部',
		5101001038: '熙康健康管理中心成都分院',
		6101001039: '陕西熙康健康管理有限公司',
		6101001040: '普惠西安兴庆南路体检中心',
		6101001041: '普惠西安经开体检中心',
		6101001042: '普惠西安高新体检中心',
		6101001043: '普惠西安曲江体检中心',
		6101001044: '普惠西安太白体检中心',
		6101001045: '普惠西安未央体检中心',
		6101001046: '西安交大店',
		6101001047: '西安经开店',
		6101001048: '西安高新店',
		6101001049: '西安高新嘉宾',
		6101001050: '西安曲江店',
		6101001051: '西安未央店',
		6101001052: '西安太白店',
		6101001053: '普惠西安太和体检中心',
		6101001054: '西安普惠劳南体检中心',
		6101001055: '西安劳动南路店',
		6104001056: '普惠咸阳体检中心',
		6104001057: '咸阳店',
		6201001058: '普惠兰州体检中心',
		4401001061: '广州公司-天河分院',
		4401001062: '从化分院-从化分院',
		4403001063: '深圳公司-健康城',
		4403001064: '深圳公司-田厦分院',
		4405001065: '汕头公司-汕头分院',
		4406001066: '佛山公司-佛山分院',
		4413001067: '惠州公司-惠州分院',
		4418001068: '清远公司-清远分院',
		4451001069: '潮州公司-潮州分院',
		5101001070: '成都公司-复城分院',
		5101001071: '成都公司-国金分院',
		6201001088: '兰州分院',
		6109001094: '德州分院',
		6109001095: '安康仁康体检',
		6103001100: '宝鸡分院',
		6108001102: '榆林分院',
		6401001108: '银川仁泰体检',
		5301001109: '仁爱医院',
		5301001110: '昆明分院',
		6501001116: '诚裕慈惠乌鲁木齐分院',
		6532001117: '诚裕慈惠和田分院',
		6502001121: '慈惠体检克拉玛依分院',
		4413001123: '惠州分院',
		4301001127: '华领-长沙分院',
		5113001130: '家美医院',
		4304001132: '康宁体检',
		4310001134: '郴州分院',
		6540001141: '友好医院',
		4301001144: '山水体检',
		6201001198: '佳龙口腔',
		6207001199: '李占伟口腔诊所',
		6201001200: '西固区牙王国数字化口腔门诊部',
		6201001201: '城关区广场南路牙王国数字化口腔门诊部',
		4419001202: '好佰年口腔（东城店）',
		4419001203: '好佰年口腔（虎门店）',
		4419001204: '东莞市寮步莞大口腔',
		4419001205: '东莞常平爱康口腔门诊部',
		4419001206: '东莞大岭山天使口腔门诊部',
		4419001207: '东莞厚街魅力天使口腔门诊部',
		4419001208: '东莞天使口腔医院',
		4419001209: '东莞樟木头天使口腔诊所',
		4419001210: '东莞长安爱雅健口腔门诊部',
		4419001211: '东莞长安天使口腔门诊部',
		4406001212: '佛山拜伦口腔门诊部',
		4413001213: '惠城斯迈尔口腔门诊',
		4407001214: '蓬江农世越口腔门诊',
		4409001215: '茂名致美口腔医院',
		4405001216: '汕头德瑞口腔门诊部',
		4403001217: '岚世纪口腔（横岗店）',
		4403001218: '正夫口腔（横岗店）',
		4403001219: '岚世纪口腔（沙井店）',
		4403001220: '深圳正强齿科',
		4403001221: '深圳格伦菲尔口腔（福田总店）',
		4403001222: '沃思顿口腔门诊部',
		4403001223: '深圳格伦菲尔口腔（布吉卓悦店）',
		4403001224: '正夫口腔（铭德龙岗总院）',
		4403001225: '正夫口腔（铭德欧景店）',
		4403001226: '深圳南州口腔门诊部',
		4403001227: '深圳侨诺口腔诊所',
		4403001228: '深圳格伦菲尔口腔（民治天虹店）',
		4403001229: '深圳格伦菲尔口腔（龙华清湖店）',
		4403001230: '黄琛口腔诊所',
		4403001231: '深圳舒美齿科（南山店）',
		4417001232: '阳江华康口腔医院',
		4417001233: '阳江雅博口腔门诊部',
		4417001234: '阳江圣健口腔门诊部',
		4408001235: '湛江德澳口腔',
		4408001236: '湛江致美口腔医院',
		4408001237: '湛江市王成口腔诊所',
		4420001238: '友佳牙科诊所',
		4420001239: '中山市西区友佳口腔门诊部东区店',
		4415001240: '陆丰德康口腔',
		4404001241: '珠海泊菲特口腔门诊部',
		4404001242: '珠海雅博士口腔门诊部',
		4401001243: '壹号口腔门诊部',
		4401001244: '广州三仁口腔门诊部',
		4406001245: '佛山南海邓志远口腔诊所',
		4401001246: '广州优越会口腔门诊部',
		4404001247: '珠海奥尔口腔门诊部',
		4419001248: '好佰年口腔（东莞寮步店）',
		4406001249: '佛山拜伦口腔南庄门诊部',
		4406001250: '佛山拜伦口腔普君门诊部',
		4453001251: '云浮拜伦口腔门诊部',
		4406001252: '佛山拜顿口腔杨文军诊所',
		4404001253: '珠海金湾拜恩口腔门诊',
		4403001254: '贝齿美口腔门诊部',
		4403001255: '储成华口腔诊所',
		4401001256: '研科口腔门诊部',
		4403001257: '深圳南州罗岗口腔门诊部',
		4403001258: '深圳南州李王胜口腔诊所',
		4401001259: '广州德道口腔门诊部',
		4401001260: '广州美蒂斯口腔门诊部',
		4401001261: '广州鹏爱口腔',
		4402001262: '康牙口腔门诊部',
		4401001263: '华医口腔门诊部',
		4403001264: '希奥口腔门诊部',
		4401001265: '广州心一口腔门诊部',
		4401001266: '华晨达宝口腔门诊',
		4419001267: '好佰年口腔（虎门丰泰店）',
		4403001268: '深圳友恩口腔诊所',
		4401001269: '研科竹料口腔门诊部',
		4403001270: '深圳艺恩口腔门诊部（沃思顿口腔）',
		4403001271: '深圳弘和口腔（欧瑞口腔门诊部）',
		4401001272: '广州德尚口腔门诊部',
		4419001273: '东莞厚街宝屯唯医口腔门诊部',
		4403001274: '岚世纪口腔（择尔店）',
		4403001275: '思杰亚洲深圳发展有限公司珮斐口腔门诊部',
		4510001276: '田东县苏医生口腔医院',
		4505001277: '北海爱齿尔口腔门诊部',
		4514001278: '崇左恒美口腔诊所',
		4503001279: '桂林美皓口腔门诊部',
		4503001280: '桂林刘永寿口腔诊所',
		4502001281: '柳州美齿口腔门诊部',
		4502001282: '皓迪口腔门诊部',
		4502001283: '柳州华喜口腔门诊部',
		4502001284: '柳州品植口腔门诊部',
		4502001285: '喜天丽口腔门诊部',
		4501001286: '南宁辉宇口腔门诊部',
		4501001287: '牙典口腔（南宁黎敏玲口腔诊所）',
		4501001288: '南宁皓乐口腔诊所',
		4501001289: '广西洋紫荆口腔茶花园店',
		4501001290: '超然口腔（南宁唐宓口腔诊所）',
		4501001291: '南宁李世平口腔诊所',
		4501001292: '南宁六维口腔门诊部',
		4501001293: '南宁象雅口腔门诊部',
		4501001294: '万象口腔（南宁欧永富口腔诊所）',
		4501001295: '西博雅口腔（南宁叶佐琳口腔诊所）',
		4501001296: '南宁蓝天口腔门诊部',
		4501001297: '南宁小骆驼口腔门诊部',
		4501001298: '广西洋紫荆口腔东沟岭店',
		4504001299: '梧州蓝天口腔医院',
		4509001300: '广西恒兴口腔门诊',
		4509001301: '黄远宁口腔诊所',
		4507001302: '蓝天口腔',
		4501001303: '南宁农群口腔诊所',
		4501001304: '南宁蓝天口腔医院有限公司金川口腔门诊部',
		4501001305: '南宁蓝天口腔医院有限公司嘉和城口腔门诊部',
		5201001306: '白云艺宁口腔诊所',
		5201001307: '信义口腔',
		5201001308: '贵阳市南明区一牙兔口腔诊所（凯丰牙科）',
		5202001309: '六盘水水城口腔医院',
		5201001310: '贵阳拜尔口腔（枣山路门诊）',
		5201001311: '贵阳拜尔口腔（金辉门诊）',
		5201001312: '贵阳拜尔口腔（花果园门诊）',
		4601001313: '海南鼎点口腔海口东湖分院',
		4601001314: '海南鼎点口腔海口国贸总院',
		4601001315: '海口龙华德恩口腔门诊部',
		4601001316: '陈新口腔门诊部',
		4601001317: '海南光华口腔医院(海医)',
		4602001318: '海南鼎点口腔三亚下洋田分院',
		4307001437: '汉寿周大桂口腔科诊所',
		4307001438: '常德益达口腔诊所',
		4304001439: '衡阳奥利齿口腔诊所',
		4304001440: '衡阳谢氏口腔门诊部',
		4312001441: '怀化赖尔口腔门诊部',
		4313001442: '康馨口腔门诊',
		4313001443: '新化优贝口腔',
		4305001444: '邵阳雅贝康口腔门诊部',
		4305001445: '隆回县齿美康口腔门诊部',
		4306001446: '雅美佳口腔诊所（蒋志韬口腔诊所）',
		4306001447: '雅悦口腔（周放口腔诊所）',
		4308001448: '张家界博瑞口腔门诊部',
		4301001449: '浏阳北盛罗记牙科诊所',
		4302001450: '株洲柏霖口腔门诊有限公司芦淞门诊部',
		4302001451: '株洲湘安口腔医院',
		4313001452: '娄底爱牙邦口腔医院',
		4301001453: '泉塘亚雅口腔门诊部',
		4301001454: '长沙县泉塘街道牙雅口腔门诊部',
		4301001455: '芙蓉亚雅口腔门诊部',
		4301001456: '长沙市岳麓区海云口腔门诊部',
		6401001607: '佳成口腔诊所',
		6301001608: '西宁华康口腔医院',
		6109001710: '安康同仁口腔门诊部',
		6103001711: '宝鸡市金台区小丁口腔诊所',
		6103001712: '宝鸡牙卫士口腔诊所',
		6107001713: '安朗口腔诊所',
		6102001714: '王益区博德口腔门诊部',
		6105001715: '蒲城明真口腔门诊',
		6101001716: '西安莲湖皓齿口腔诊所',
		6101001717: '西安嘉佳京华口腔',
		6101001718: '陕西皓月口腔医疗有限公司',
		6101001719: '陕西尚甫商贸有限公司雁塔口腔诊所',
		6101001720: '西安雁塔刘津茹口腔诊所',
		6101001721: '西安雁塔张绍山口腔诊所',
		6101001722: '西安雁塔曲江强森医院',
		6101001723: '西安长安琅琅口腔门诊部',
		6101001724: '西安长安孙艳梅口腔诊所',
		6101001725: '西安长安为民口腔门诊部',
		6108001726: '神木市潘慧聪口腔诊所',
		6108001727: '榆林口腔医院',
		6101001728: '西安未央美琅口腔诊所',
		6101001729: '禾马西安口腔医疗有限公司雁塔口腔诊所',
		6101001730: '西安未央铁齿铜牙口腔',
		6101001731: '西安德美口腔医院管理有限公司曲江新区口腔门诊部',
		6101001732: '西安未央伯牙利口腔诊所',
		6101001733: '西安莲湖马克口腔诊所',
		5101001746: '天府新区华阳青禾口腔',
		5101001747: '成都武侯贝壳口腔门诊部',
		5101001748: '海博牙科',
		5113001749: '远大口腔顺庆分院',
		5113001750: '远大口腔北城分院',
		5113001751: '南充博瑞口腔诊所',
		5101001752: '成都伯菲口腔',
		5101001753: '成都金牛菁品口腔门诊部',
		5107001754: '思微尔口腔三台店',
		5107001755: '思微尔口腔绵阳店',
		5101001756: '思微尔口腔成都店',
		5101001757: '成都双流唐之美口腔门诊部（唐牙科）',
		5101001758: '成都武侯圣亚口腔(唐牙科)',
		5101001759: '成都武侯唐牙乐口腔(唐牙科)',
		5101001760: '成都锦江一唐牙科诊所',
		5101001761: '成都天府新区唐艺美口腔（唐牙科）',
		5101001762: '武侯三唐口腔诊所(唐牙科)',
		5101001763: '成华尔康诊所（唐牙科）',
		5101001764: '锦江唐牙科口腔诊',
		5101001765: '武侯区二唐牙科诊所',
		5101001766: '成华一唐牙科口腔诊所',
		5101001767: '青羊区二唐牙科门诊部',
		5101001768: '成都武侯唐建华口腔（唐牙科）',
		5101001769: '青羊区唐牙科门诊部',
		5101001770: '武侯区德蓉口腔诊所(唐牙科)',
		5101001771: '成都市锦江区唐建华口腔(唐牙科)',
		5101001772: '成都高新区唐建华口腔（唐牙科）',
		5101001773: '成都武侯老唐牙科口腔诊所',
		5101001774: '武侯圣元口腔（唐牙科）',
		5101001775: '武侯区纯福雅乐口腔门诊部',
		5326001777: '文山拜尔口腔门诊部',
		5329001778: '思美齐口腔',
		5325001779: '蒙自吉祥口腔门诊部',
		5301001780: '尚爱韩美口腔环城南路院区',
		5301001781: '官渡艾洁口腔门诊部',
		5301001782: '云南德普医疗有限公司双桥路口腔门诊部',
		5301001783: '尚爱韩美口腔北京路院区',
		5301001784: '昆明先行口腔诊所有限公司东庄诊所',
		5301001785: '齿盾口腔五华馨悦尚居店',
		5301001786: '五华长鹏口腔诊所',
		5301001787: '长鹏口腔（丽阳星城）',
		5301001788: '长鹏口腔（凯旋店）',
		5301001789: '长鹏口腔（兴苑店）',
		5301001790: '长鹏口腔（香樟俊园店）',
		5301001791: '长鹏口腔（世纪城店）',
		5301001792: '尚爱韩美口腔润城店',
		5303001793: '陆良蓝天诊所',
		5301001794: '齿盾口腔泰旸新城店（宗宏口腔）',
		5301001795: '昆明昆德口腔诊所',
		5001001848: '瑞泰久悦齿科巴南店',
		5001001849: '瑞泰久悦口腔龙湖源著门诊',
		5001001850: '江北赵慧口腔诊所',
		5001001851: '重庆汇恩医院江北口腔门诊部',
		5001001852: '邓亚宁口腔诊所',
		5001001853: '重庆红十字卫东口腔诊所',
		5001001854: '重庆佳运口腔门诊',
		5001001855: '重庆正舸口腔（天地店）',
		5001001856: '重庆正舸口腔（南坪店)',
		5001001857: '邵学东口腔诊所',
		5001001858: '万州品善口腔门诊部',
		5001001859: '思阳口腔九龙坡门店',
		5001001860: '思阳口腔渝北门店',
		5001001861: '重庆亿佰口腔门诊部',
		5001001862: '重庆金开八益口腔门诊部',
		5001001863: '渝北水晶八益牙科门诊部',
		5001001864: '南岸八益口腔门诊部',
		5001001865: '沙坪坝八益口腔门诊部',
		5001001866: '渝中区大坪八益牙科门诊部',
		5001001867: '重庆正舸口腔有限公司南岸金辉广场口腔诊所'
	},
	city_list: {
		110100: '北京市',
		120100: '天津市',
		130100: '石家庄市',
		130200: '唐山市',
		130300: '秦皇岛市',
		130400: '邯郸市',
		130500: '邢台市',
		130600: '保定市',
		130700: '张家口市',
		130800: '承德市',
		130900: '沧州市',
		131000: '廊坊市',
		131100: '衡水市',
		140100: '太原市',
		140200: '大同市',
		140300: '阳泉市',
		140400: '长治市',
		140500: '晋城市',
		140600: '朔州市',
		140700: '晋中市',
		140800: '运城市',
		140900: '忻州市',
		141000: '临汾市',
		141100: '吕梁市',
		150100: '呼和浩特市',
		150200: '包头市',
		150300: '乌海市',
		150400: '赤峰市',
		150500: '通辽市',
		150600: '鄂尔多斯市',
		150700: '呼伦贝尔市',
		150800: '巴彦淖尔市',
		150900: '乌兰察布市',
		152200: '兴安盟',
		152500: '锡林郭勒盟',
		152900: '阿拉善盟',
		210100: '沈阳市',
		210200: '大连市',
		210300: '鞍山市',
		210400: '抚顺市',
		210500: '本溪市',
		210600: '丹东市',
		210700: '锦州市',
		210800: '营口市',
		210900: '阜新市',
		211000: '辽阳市',
		211100: '盘锦市',
		211200: '铁岭市',
		211300: '朝阳市',
		211400: '葫芦岛市',
		220100: '长春市',
		220200: '吉林市',
		220300: '四平市',
		220400: '辽源市',
		220500: '通化市',
		220600: '白山市',
		220700: '松原市',
		220800: '白城市',
		222400: '延边朝鲜族自治州',
		230100: '哈尔滨市',
		230200: '齐齐哈尔市',
		230300: '鸡西市',
		230400: '鹤岗市',
		230500: '双鸭山市',
		230600: '大庆市',
		230700: '伊春市',
		230800: '佳木斯市',
		230900: '七台河市',
		231000: '牡丹江市',
		231100: '黑河市',
		231200: '绥化市',
		232700: '大兴安岭地区',
		310100: '上海市',
		320100: '南京市',
		320200: '无锡市',
		320300: '徐州市',
		320400: '常州市',
		320500: '苏州市',
		320600: '南通市',
		320700: '连云港市',
		320800: '淮安市',
		320900: '盐城市',
		321000: '扬州市',
		321100: '镇江市',
		321200: '泰州市',
		321300: '宿迁市',
		330100: '杭州市',
		330200: '宁波市',
		330300: '温州市',
		330400: '嘉兴市',
		330500: '湖州市',
		330600: '绍兴市',
		330700: '金华市',
		330800: '衢州市',
		330900: '舟山市',
		331000: '台州市',
		331100: '丽水市',
		340100: '合肥市',
		340200: '芜湖市',
		340300: '蚌埠市',
		340400: '淮南市',
		340500: '马鞍山市',
		340600: '淮北市',
		340700: '铜陵市',
		340800: '安庆市',
		341000: '黄山市',
		341100: '滁州市',
		341200: '阜阳市',
		341300: '宿州市',
		341500: '六安市',
		341600: '亳州市',
		341700: '池州市',
		341800: '宣城市',
		350100: '福州市',
		350200: '厦门市',
		350300: '莆田市',
		350400: '三明市',
		350500: '泉州市',
		350600: '漳州市',
		350700: '南平市',
		350800: '龙岩市',
		350900: '宁德市',
		360100: '南昌市',
		360200: '景德镇市',
		360300: '萍乡市',
		360400: '九江市',
		360500: '新余市',
		360600: '鹰潭市',
		360700: '赣州市',
		360800: '吉安市',
		360900: '宜春市',
		361000: '抚州市',
		361100: '上饶市',
		370100: '济南市',
		370200: '青岛市',
		370300: '淄博市',
		370400: '枣庄市',
		370500: '东营市',
		370600: '烟台市',
		370700: '潍坊市',
		370800: '济宁市',
		370900: '泰安市',
		371000: '威海市',
		371100: '日照市',
		371300: '临沂市',
		371400: '德州市',
		371500: '聊城市',
		371600: '滨州市',
		371700: '菏泽市',
		410100: '郑州市',
		410200: '开封市',
		410300: '洛阳市',
		410400: '平顶山市',
		410500: '安阳市',
		410600: '鹤壁市',
		410700: '新乡市',
		410800: '焦作市',
		410900: '濮阳市',
		411000: '许昌市',
		411100: '漯河市',
		411200: '三门峡市',
		411300: '南阳市',
		411400: '商丘市',
		411500: '信阳市',
		411600: '周口市',
		411700: '驻马店市',
		419000: '省直辖县',
		420100: '武汉市',
		420200: '黄石市',
		420300: '十堰市',
		420500: '宜昌市',
		420600: '襄阳市',
		420700: '鄂州市',
		420800: '荆门市',
		420900: '孝感市',
		421000: '荆州市',
		421100: '黄冈市',
		421200: '咸宁市',
		421300: '随州市',
		422800: '恩施土家族苗族自治州',
		429000: '省直辖县',
		430100: '长沙市',
		430200: '株洲市',
		430300: '湘潭市',
		430400: '衡阳市',
		430500: '邵阳市',
		430600: '岳阳市',
		430700: '常德市',
		430800: '张家界市',
		430900: '益阳市',
		431000: '郴州市',
		431100: '永州市',
		431200: '怀化市',
		431300: '娄底市',
		433100: '湘西土家族苗族自治州',
		440100: '广州市',
		440200: '韶关市',
		440300: '深圳市',
		440400: '珠海市',
		440500: '汕头市',
		440600: '佛山市',
		440700: '江门市',
		440800: '湛江市',
		440900: '茂名市',
		441200: '肇庆市',
		441300: '惠州市',
		441400: '梅州市',
		441500: '汕尾市',
		441600: '河源市',
		441700: '阳江市',
		441800: '清远市',
		441900: '东莞市',
		442000: '中山市',
		445100: '潮州市',
		445200: '揭阳市',
		445300: '云浮市',
		450100: '南宁市',
		450200: '柳州市',
		450300: '桂林市',
		450400: '梧州市',
		450500: '北海市',
		450600: '防城港市',
		450700: '钦州市',
		450800: '贵港市',
		450900: '玉林市',
		451000: '百色市',
		451100: '贺州市',
		451200: '河池市',
		451300: '来宾市',
		451400: '崇左市',
		460100: '海口市',
		460200: '三亚市',
		460300: '三沙市',
		460400: '儋州市',
		469000: '省直辖县',
		500100: '重庆市',
		500200: '县',
		510100: '成都市',
		510300: '自贡市',
		510400: '攀枝花市',
		510500: '泸州市',
		510600: '德阳市',
		510700: '绵阳市',
		510800: '广元市',
		510900: '遂宁市',
		511000: '内江市',
		511100: '乐山市',
		511300: '南充市',
		511400: '眉山市',
		511500: '宜宾市',
		511600: '广安市',
		511700: '达州市',
		511800: '雅安市',
		511900: '巴中市',
		512000: '资阳市',
		513200: '阿坝藏族羌族自治州',
		513300: '甘孜藏族自治州',
		513400: '凉山彝族自治州',
		520100: '贵阳市',
		520200: '六盘水市',
		520300: '遵义市',
		520400: '安顺市',
		520500: '毕节市',
		520600: '铜仁市',
		522300: '黔西南布依族苗族自治州',
		522600: '黔东南苗族侗族自治州',
		522700: '黔南布依族苗族自治州',
		530100: '昆明市',
		530300: '曲靖市',
		530400: '玉溪市',
		530500: '保山市',
		530600: '昭通市',
		530700: '丽江市',
		530800: '普洱市',
		530900: '临沧市',
		532300: '楚雄彝族自治州',
		532500: '红河哈尼族彝族自治州',
		532600: '文山壮族苗族自治州',
		532800: '西双版纳傣族自治州',
		532900: '大理白族自治州',
		533100: '德宏傣族景颇族自治州',
		533300: '怒江傈僳族自治州',
		533400: '迪庆藏族自治州',
		540100: '拉萨市',
		540200: '日喀则市',
		540300: '昌都市',
		540400: '林芝市',
		540500: '山南市',
		540600: '那曲市',
		542500: '阿里地区',
		610100: '西安市',
		610200: '铜川市',
		610300: '宝鸡市',
		610400: '咸阳市',
		610500: '渭南市',
		610600: '延安市',
		610700: '汉中市',
		610800: '榆林市',
		610900: '安康市',
		611000: '商洛市',
		620100: '兰州市',
		620200: '嘉峪关市',
		620300: '金昌市',
		620400: '白银市',
		620500: '天水市',
		620600: '武威市',
		620700: '张掖市',
		620800: '平凉市',
		620900: '酒泉市',
		621000: '庆阳市',
		621100: '定西市',
		621200: '陇南市',
		622900: '临夏回族自治州',
		623000: '甘南藏族自治州',
		630100: '西宁市',
		630200: '海东市',
		632200: '海北藏族自治州',
		632300: '黄南藏族自治州',
		632500: '海南藏族自治州',
		632600: '果洛藏族自治州',
		632700: '玉树藏族自治州',
		632800: '海西蒙古族藏族自治州',
		640100: '银川市',
		640200: '石嘴山市',
		640300: '吴忠市',
		640400: '固原市',
		640500: '中卫市',
		650100: '乌鲁木齐市',
		650200: '克拉玛依市',
		650400: '吐鲁番市',
		650500: '哈密市',
		652300: '昌吉回族自治州',
		652700: '博尔塔拉蒙古自治州',
		652800: '巴音郭楞蒙古自治州',
		652900: '阿克苏地区',
		653000: '克孜勒苏柯尔克孜自治州',
		653100: '喀什地区',
		653200: '和田地区',
		654000: '伊犁哈萨克自治州',
		654200: '塔城地区',
		654300: '阿勒泰地区',
		659000: '自治区直辖县级行政区划',
		710100: '台北市',
		710200: '高雄市',
		710300: '台南市',
		710400: '台中市',
		710500: '金门县',
		710600: '南投县',
		710700: '基隆市',
		710800: '新竹市',
		710900: '嘉义市',
		711100: '新北市',
		711200: '宜兰县',
		711300: '新竹县',
		711400: '桃园县',
		711500: '苗栗县',
		711700: '彰化县',
		711900: '嘉义县',
		712100: '云林县',
		712400: '屏东县',
		712500: '台东县',
		712600: '花莲县',
		712700: '澎湖县',
		712800: '连江县',
		810100: '香港岛',
		810200: '九龙',
		810300: '新界',
		820100: '澳门半岛',
		820200: '离岛'
	}
};