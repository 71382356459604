<template>
	<div class="container">
		<img class="banner" :src="bannerUrl"/>
		<div class="order-info">
			<div class="order-info-title border flex f-ai-c">
				<img class="icon" :src="require('@/assets/images/icon-1.png')"/>
				<span class="title">预约信息</span>
				<span class="tips t1">(必填)</span>
			</div>
			<van-form validate-first ref="form">
				<van-field v-model="dataForm.name" label="姓名" placeholder="请输入姓名" required :rules="nameRul" clearable/>
				<van-field v-model="dataForm.mobilePhone" label="手机号" required :maxlength="11" placeholder="请输入手机号" :rules="mobilePhoneRul" clearable/>
				<van-field v-model="dataForm.idcard" label="身份证" required :maxlength="18" placeholder="请输入身份证号" :rules="idCardRul" clearable/>
				<van-field name="radio" label="婚姻状况" class="marital-status" required v-if="dataForm.service === 'physicalExam'">
					<template #input>
						<van-radio-group v-model="dataForm.maritalStatus" direction="horizontal" icon-size="16px">
							<van-radio name="1">未婚</van-radio>
							<van-radio name="2">已婚</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field
					readonly
					clickable
					name="datetimePicker"
					:value="dataForm.visitTime"
					label="预约时间"
					placeholder="请选择"
					@click="showVisitTimePicker = true"
					:right-icon="require('@/assets/images/icon-right.png')"
					:rules="visitTimeRul"
					required
				/>
				<van-calendar v-model="showVisitTimePicker" type="range" @confirm="onVisitTimeConfirm" :allow-same-day="true" color="#2491FF"/>
				<van-field
					v-model="dataForm.hospital"
					is-link
					readonly
					label="预约机构"
					placeholder="请选择"
					@click="showHospital = true"
					:right-icon="require('@/assets/images/icon-right.png')"
					:rules="hospitalRul"
					required
				/>
				<van-popup v-model="showHospital" round position="bottom">
					<van-cascader
						v-model="hospitalValue"
						title="预约机构"
						:options="options"
						@close="showHospital = false"
						@change="onChange"
						@finish="onFinish"
						active-color="#2491FF"
					/>
				</van-popup>
				<van-field v-model="dataForm.remark" label="备注" placeholder="请输入备注" clearable/>
				<!-- <van-field
					v-model="dataForm.visitDept"
					is-link
					readonly
					label="就诊科室"
					placeholder="请选择"
					@click="showDeptPicker = true"
					:right-icon="require('@/assets/images/icon-right.png')"
					:rules="deptRul"
				/>
				<van-popup v-model="showDeptPicker" round position="bottom">
					<van-cascader
						v-model="deptValue"
						title="就诊科室"
						:options="depts"
						@close="showDeptPicker = false"
						@change="onDeptChange"
						@finish="onDeptFinish"
						active-color="#2491FF"
					/>
				</van-popup>
				<van-field
					readonly
					clickable
					name="picker"
					:value="dataForm.serviceType"
					label="服务类型"
					placeholder="请选择"
					@click="showServiceTypePicker = true"
					:right-icon="require('@/assets/images/icon-right.png')"
					:rules="serviceTypeRul"
				/>
				<van-popup v-model="showServiceTypePicker" position="bottom">
					<van-picker
						show-toolbar
						:columns="serviceTypeList"
						@confirm="onServiceTypeConfirm"
						@cancel="showServiceTypePicker = false"
					/>
				</van-popup> -->
			</van-form>
		</div>
		<!-- <div class="order-info">
			<div class="order-info-title border flex f-ai-c">
				<img class="icon" :src="require('@/assets/images/icon-2.png')"/>
				<span class="title">证件上传</span>
			</div>
			<div class="border card-border">
				<span class="card-title">身份证正反面</span>
				<div class="flex f-jc-sb">
					<div class="upload-style-container flex f-jc-c f-ai-c">
						<input type="file" class="img-field" id="cardImg1" @change="fileChange">
						<div class="upload-style flex f-d-c f-ai-c f-jc-c" v-if="!dataForm.cardImg1" @click="fileClick(1)">
							<img :src="require('@/assets/images/camaro.png')" alt="">
							<span>点击上传人像面</span>
						</div>
						<img :src="dataForm.cardImg1.url" class="img-preview" alt="" v-else @click="fileClick(1)">
					</div>
					<div class="upload-style-container flex f-jc-c f-ai-c">
						<input type="file" class="img-field" id="cardImg2" @change="fileChange">
						<div class="upload-style flex f-d-c f-ai-c f-jc-c" v-if="!dataForm.cardImg2" @click="fileClick(2)">
							<img :src="require('@/assets/images/camaro.png')" alt="">
							<span>点击上传人像面</span>
						</div>
						<img :src="dataForm.cardImg2.url" class="img-preview" alt="" v-else @click="fileClick(2)">
					</div>
				</div>
			</div>
			<div class="border card-border">
				<span class="card-title">医保卡正反面</span>
				<div class="flex f-jc-sb">
					<div class="upload-style-container flex f-jc-c f-ai-c">
						<input type="file" class="img-field" id="cardImg3" @change="fileChange">
						<div class="upload-style flex f-d-c f-ai-c f-jc-c" v-if="!dataForm.cardImg3" @click="fileClick(3)">
							<img :src="require('@/assets/images/camaro.png')" alt="">
							<span>点击上传人像面</span>
						</div>
						<img :src="dataForm.cardImg3.url" class="img-preview" alt="" v-else @click="fileClick(3)">
					</div>
					<div class="upload-style-container flex f-jc-c f-ai-c">
						<input type="file" class="img-field" id="cardImg4" @change="fileChange">
						<div class="upload-style flex f-d-c f-ai-c f-jc-c" v-if="!dataForm.cardImg4" @click="fileClick(4)">
							<img :src="require('@/assets/images/camaro.png')" alt="">
							<span>点击上传人像面</span>
						</div>
						<img :src="dataForm.cardImg4.url" class="img-preview" alt="" v-else @click="fileClick(4)">
					</div>
				</div>
			</div>
		</div> -->
		<div class="button flex f-jc-c" @click="commit" native-type="submit">
			<img :src="require('@/assets/images/button.png')" alt="">
			<span>提交</span>
		</div>
	</div>
</template>
<script>
import { dateFormat } from '@/utils/time';
import defaultBanner from '@/assets/images/banner.png';
import physicalExamBanner from '@/assets/images/physicalExamBanner.png';
import teethCleaningBanner from '@/assets/images/teethCleaningBanner.png';
import { validatePhone, validateIdCard } from '@/utils';
import hosorg from '@/lab/hosorg';
import peorg from '@/lab/peorg';
import tcorg from '@/lab/tcorg';
import department from '@/lab/department';
/* import { Toast } from 'vant';
import OSS from 'ali-oss';
import md5 from 'md5';
import { aliossToken } from '@/request/api/alioss'; */
import { addOrderTd } from '@/request/api';

const mobilePhoneValidator = function (value) {
	return validatePhone(value);
};
const idCardValidator = function (value) {
	return validateIdCard(value);
};
export default {
	name: 'OrderEdit',
	data () {
		return {
			bannerUrl: defaultBanner,
			orglist: hosorg,
			dataForm: {
				customer: '',
				service: '',
				channel: '',
				name: '',
				mobilePhone: '',
				idcard: '',
				visitTime: '',
				hospital: '',
				visitDept: '',
				isAppoint: true,
				doctorName: '',
				serviceType: '',
				cardImg1: '', // 身份证人像面
				cardImg2: '', // 身份证国徽面
				cardImg3: '', // 医保卡人像面
				cardImg4: '', // 医保卡国徽面
				alossInfo: '',
				remark: '',
				maritalStatus: '1' // 婚姻状况
			},
			isAppointTxt: '',
			deptList: ['耳鼻喉科', '眼科', '儿科', '心内科', '肛肠科', '骨科'],
			appointList: ['是', '否'],
			doctorList: ['王医生', '李医生', '张医生', '萧医生', '孙医生'],
			serviceTypeList: ['门诊预约', '住院手术', '检查加急', '远程问诊', '其他'],
			cascaderValue: '',
			hospitalValue: '',
			deptValue: '',
			// 选项列表，children 代表子选项，支持多级嵌套
			options: [],
			checkedProvince: [], // 选择过的省份列表
			checkedCity: [], // 选择过的地级市列表
			checkedCounty: [], // 选择过的县级市列表
			depts: [], // 科室分类列表
			checkedDeptP: [],
			checkedDeptType: [], // 选中的科室分类
			checkedDept: [], // 选中的科室
			currentDate: new Date(),
			showVisitTimePicker: false,
			showAppointPicker: false,
			showDeptPicker: false,
			showDoctorPicker: false,
			showServiceTypePicker: false,
			showHospital: false,
			nameRul: [{ required: true, message: '请填写姓名', name: 'name' }],
			visitTimeRul: [{ required: true, message: '请选择预约时间' }],
			hospitalRul: [{ required: true, message: '请选择预约机构' }],
			deptRul: [{ required: true, message: '请选择就诊科室' }],
			serviceTypeRul: [{ required: true, message: '请选择服务类型' }],
			doctorRul: [{ required: true, message: '请选择医生' }],
			mobilePhoneRul: [
				{ required: true, validator: mobilePhoneValidator, message: '请填写正确的手机号' }
			],
			idCardRul: [
				{ required: true, validator: idCardValidator, message: '请填写正确的身份证号' }
			],
			fieldIndex: 0, // 当前正在操作上传的文本域
			canSubmit: true
		};
	},
	created () {
		this.formatArea();
		this.formatDept();
		this.dataForm = {
			...this.dataForm,
			...this.$route.query
		};
	},
	mounted () {
		let title = '佰阅健康';
		const _query = this.$route.query;
		if (_query.service === 'teethCleaning') {
			title = '洁牙预约';
			this.bannerUrl = teethCleaningBanner;
			this.orglist = tcorg;
		} else if (_query.service === 'physicalExam') {
			title = '体检预约';
			this.bannerUrl = physicalExamBanner;
			this.orglist = peorg;
		}
		document.title = title;
	},
	methods: {
		formatArea () {
			for (const i in this.orglist.province_list) {
				this.options.push({
					text: this.orglist.province_list[i],
					value: i,
					children: []
				});
			}
		},
		formatDept () {
			for (const i in department.dept_type) {
				this.depts.push({
					text: department.dept_type[i],
					value: i,
					children: []
				});
			}
		},
		formatter (type, val) {
			if (type === 'year') {
				return val + '年';
			}
			if (type === 'month') {
				return val + '月';
			}
			if (type === 'day') {
				return val + '日';
			}
			return val;
		},
		onConfirm (val) {
			this.dataForm.visitTime = dateFormat(val, 'YYYY-MM-DD');
			this.showVisitTimePicker = false;
		},
		/* onDoctorConfirm (val) {
			this.dataForm.doctorName = val;
			this.showDoctorPicker = false;
		},
		onAppointConfirm (val) {
			this.dataForm.isAppoint = val === '是';
			this.isAppointTxt = val;
			this.showAppointPicker = false;
		}, */
		onVisitTimeConfirm ([start, end]) {
			this.showVisitTimePicker = false;
			this.dataForm.visitTime = `${dateFormat(start, 'YYYY年MM月DD日')}--${dateFormat(end, 'YYYY年MM月DD日')}`;
		},
		/* onDeptConfirm (val) {
			this.dataForm.visitDept = val;
			this.showDeptPicker = false;
		},
		onServiceTypeConfirm (val) {
			this.dataForm.serviceType = val;
			this.showServiceTypePicker = false;
		},
		onDeptChange (val) {
			if (val.tabIndex < 1) {
				const index = val.tabIndex;
				const value = val.value;
				const list = ['dept_type', 'dept_list'];
				const checked = ['checkedDeptType', 'checkedDept'];
				const end = [2];
				if (this[checked[index]].indexOf(value) === -1) {
					this[checked[index]].push(value);
					const prefix = value.slice(0, end[index]);
					for (const key in department[list[index + 1]]) {
						if (key.toString().startsWith(prefix)) {
							val.selectedOptions[index].children.push({
								text: department[list[index + 1]][key],
								value: key,
								children: index === 0 ? '' : []
							});
						}
					}
					// 如果某个子级为空
					if (!val.selectedOptions[index].children.length) {
						val.selectedOptions[index].children = [
							{
								text: '暂无科室',
								value: '-1'
							}
						];
					}
				}
			}
		},
		onDeptFinish ({ selectedOptions }) {
			this.showDeptPicker = false;
			if (selectedOptions[selectedOptions.length - 1].value === '-1') {
				this.dataForm.visitDept = '';
			} else {
				this.dataForm.visitDept = selectedOptions[1].text;
			}
		}, */
		onChange (val) {
			if (val.tabIndex < 2) {
				const index = val.tabIndex;
				const value = val.value;
				const list = ['province_list', 'city_list', 'hospital_list'];
				const checked = ['checkedProvince', 'checkedCity'];
				const end = [2, 4];
				if (this[checked[index]].indexOf(value) === -1) {
					this[checked[index]].push(value);
					const prefix = value.slice(0, end[index]);
					for (const key in this.orglist[list[index + 1]]) {
						if (key.toString().startsWith(prefix)) {
							val.selectedOptions[index].children.push({
								text: this.orglist[list[index + 1]][key],
								value: key,
								children: index === 1 ? '' : []
							});
						}
					}
					// 如果某个子级为空
					if (!val.selectedOptions[index].children.length) {
						val.selectedOptions[index].children = [
							{
								text: '暂无医院',
								value: '-1'
							}
						];
					}
				}
			}
		},
		onFinish ({ selectedOptions }) {
			this.showHospital = false;
			if (selectedOptions[selectedOptions.length - 1].value === '-1') {
				this.dataForm.hospital = '';
			} else {
				this.dataForm.hospital = selectedOptions.map((option) => option.text).join(' ');
			}
		},
		/* async getTokenAndUpload (file, cb) {
			if (!this.alossInfo) {
				this.alossInfo = await aliossToken();
			}
			const imgType = file.type.split('/')[1];
			const filename = file.name + file.size; // md5对图片名称进行加密
			const keyValue = '' + md5(new Date() + filename) + '.' + imgType;
			const client = new OSS({
				region: this.alossInfo.region,
				stsToken: this.alossInfo.SecurityToken,
				accessKeyId: this.alossInfo.AccessKeyId,
				accessKeySecret: this.alossInfo.AccessKeySecret,
				bucket: this.alossInfo.bucket
			});
			client
				.put('vip-vas-public/' + keyValue, file)
				.then((res) => {
					if (res.url) {
						file.url = res.url;
						file.message = '';
						cb(res);
					} else {
						Toast('图片上传失败', 'error');
						cb();
					}
				})
				.catch((err) => {
					console.log('message', err.message);
					Toast('图片上传失败', 'error');
					cb();
				});
		}, */
		/* fileChange (event) {
			const file = event.target.files[0];
			let flag = false;
			if (file.size / 1024 / 1024 / 5 > 1) {
				Toast('图片大小不能超过5M');
			} else {
				flag = true;
				this.getTokenAndUpload(file, (res) => {
					if (res) {
						this.dataForm[`cardImg${this.fieldIndex}`] = { name: res.name, url: res.url };
					}
				});
			}
			return flag;
		}, */
		/* fileClick (key) {
			this.fieldIndex = key;
			document.getElementById(`cardImg${key}`).click();
		}, */
		commit () {
			if (this.canSubmit) {
				this.$refs.form.validate().then(data => {
					this.canSubmit = false;
					addOrderTd({
						...this.dataForm/* ,
						idCardImg1: this.dataForm.cardImg1.url,
						idCardImg2: this.dataForm.cardImg2.url,
						medicalInsuranceCard1: this.dataForm.cardImg3.url,
						medicalInsuranceCard2: this.dataForm.cardImg4.url */
					}).then(data => {
						this.canSubmit = true;
						if (data && data.ok === 1) {
							this.$root.go('/success');
						}
					});
				}).catch(e => {});
			}
		}
	}
};
</script>
<style lang="less" scoped>
	.border {
		border-bottom: 1px solid #F2F2F2;
	}
	.card-border {
		padding-bottom: 20px;
	}
	.container {
		padding: 10px 12px;
		background-color: #F2F5F7;
		.banner {
			width: 100%;
		}
		.order-info {
			background: #FFFFFF;
			border-radius: 8px;
			margin-top: 10px;
			padding: 16px;
			&-title {
				padding-bottom: 15px;
				.icon {
					width: 22px;
					height: 22px;
				}
				.title {
					font-size: 17px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #202020;
					margin: 0 10px 0 2px;
				}
				.tips {
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
				}
				.t1 {
					color: #FF9938;
				}
				.t2 {
					color: #AFAFAF;
				}
			}
		}
		.card-title {
			font-size: 15px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #4D4D4D;
			margin-top: 18px;
			margin-bottom: 20px;
		}
		.upload-style-container {
			width: 155px;
			height: 100px;
			border-radius: 3px;
			border: 1px dashed #979797;
			.img-field {
				display: none;
			}
			.img-preview {
				width: 143px;
				height: 92px;
				margin: 4px 2px 8px 4px;
			}
			.upload-style {
				img {
					width: 36px;
					height: 36px;
				}
				span {
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #4D4D4D;
				}
				.preview-cover {
					img {
						width: 143px !important;
						height: 92px !important;
					}
				}
			}
		}
		.button {
			height: 40px;
			margin-top: 32px;
			margin-bottom: 54px;
			position: relative;
			img {
				width: 343px;
				height: 40px;
			}
			span {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #FFFFFF;
			}
		}
		.marital-status {
			::v-deep .van-field__control--custom {
				display: flex;
				justify-content: end;
			}
		}
	}
</style>