// 水滴 -- 洁牙机构列表
export default {
	province_list: {
		110000: '北京市',
		120000: '天津市',
		130000: '河北省',
		140000: '山西省',
		150000: '内蒙古自治区',
		210000: '辽宁省',
		220000: '吉林省',
		230000: '黑龙江省',
		310000: '上海市',
		320000: '江苏省',
		330000: '浙江省',
		340000: '安徽省',
		350000: '福建省',
		360000: '江西省',
		370000: '山东省',
		410000: '河南省',
		420000: '湖北省',
		430000: '湖南省',
		440000: '广东省',
		450000: '广西壮族自治区',
		460000: '海南省',
		500000: '重庆市',
		510000: '四川省',
		520000: '贵州省',
		530000: '云南省',
		// 540000: '西藏自治区',
		610000: '陕西省',
		620000: '甘肃省',
		630000: '青海省',
		640000: '宁夏回族自治区'
		// 650000: '新疆维吾尔自治区',
		// 710000: '台湾省',
		// 810000: '香港特别行政区',
		// 820000: '澳门特别行政区',
		// 900000: '海外'
	},
	hospital_list: {
		3408000: '桐城亚安口腔门诊部',
		3408001: '安庆朱小龙口腔医院',
		3408002: '安庆朱小龙口腔医院光彩门诊部',
		3408003: '安庆贝尔口腔门诊部',
		3408004: '安庆燚燊综合门诊部',
		3408005: '安庆朱小龙口腔医院东部院区',
		3408006: '安庆朱小龙口腔医院菱湖门诊部',
		3416007: '利辛爱齿口腔第二门诊部',
		3416008: '利辛爱齿口腔门诊部',
		3416009: '谯城小天使口腔门诊部',
		11010028: '海医汇国贸店',
		11010029: '北京瑞程医院管理有限公司第二口腔门诊部（瑞泰齿科双井店）',
		11010030: '瑞泰齿科望京店',
		11010031: '北京瑞程医院管理有限公司瑞泰口腔医院(瑞泰齿科北苑店)',
		11010032: '北京佳和口腔门诊部',
		11010033: '北京健翔口腔门诊部',
		11010034: '北京启康昊城口腔门诊部',
		11010035: '北京瑞程医院管理有限公司第三口腔诊所（瑞泰齿科亦庄店）',
		11010036: '北京瑞程医院管理有限公司第六口腔门诊部（瑞泰齿科工体店）',
		11010037: '北京瑞程医院管理有限公司第四口腔门诊部（瑞泰齿科上地店）',
		11010038: '北京牙管家口腔诊所',
		11010039: '瑞泰齿科陶然亭店',
		11010040: '北京悦牙口腔（大兴顺康店）',
		11010041: '北京晴雪园雅德嘉口腔门诊部',
		11010042: '北京瑞程医院管理有限公司（瑞泰齿科丰台分院）',
		34010016: '合肥经开区现代口腔门诊部（朱小龙连锁）',
		34010017: '佳德口腔包河分院',
		34010018: '佳德口腔肥西分院',
		34010025: '合肥靓美口腔医院（总院）',
		34010026: '合肥靓美口腔门诊部（经开店）',
		34030024: '爱牙乐口腔门诊部（大学城店）',
		34050021: '马鞍山鑫马口腔医院',
		34110027: '金陵口腔门诊部',
		34120011: '阜阳爱齿口腔门诊部',
		34120012: '阜阳贝德口腔门诊部',
		34120013: '阜阳松华口腔门诊部',
		34120014: '颍州爱齿口腔门诊部',
		34120015: '颍州贝德口腔门诊部',
		34150019: '六安悦牙口腔门诊部',
		34150020: '六安竹子口腔医院',
		34160023: '蒙城县贝德口腔医院',
		34170010: '东至亚安口腔门诊部',
		34180022: '徐岚口腔门诊部',
		35010043: '晋安区三颗牙口腔门诊部',
		35030051: '荔城金豆芽口腔门诊部',
		35040047: '博悦口腔门诊部',
		35040048: '三明梅列十博悦口腔门诊部',
		35050044: '泉州丰泽维乐口腔门诊',
		35050045: '石狮李金标口腔门诊部',
		35050046: '石狮市金恩美口腔门诊部',
		35050049: '泉州丰泽维乐云贝店',
		35050050: '泉州维乐口腔晋江万达店',
		35050052: '晋江青阳贝成口腔门诊部',
		44010098: '壹号口腔门诊部',
		44010099: '广州三仁口腔门诊部',
		44030072: '岚世纪口腔（横岗店）',
		44030073: '正夫口腔（横岗店）',
		44030074: '岚世纪口腔（沙井店）',
		44030075: '深圳正强齿科',
		44030076: '深圳格伦菲尔口腔（福田总店）',
		44030077: '沃思顿口腔门诊部',
		44030078: '深圳格伦菲尔口腔（布吉卓悦店）',
		44030079: '正夫口腔（铭德龙岗总院）',
		44030080: '正夫口腔（铭德欧景店）',
		44030081: '深圳南州口腔门诊部',
		44030082: '深圳侨诺口腔诊所',
		44030083: '深圳格伦菲尔口腔（民治天虹店）',
		44030084: '深圳格伦菲尔口腔（龙华清湖店）',
		44030085: '黄琛口腔诊所',
		44030086: '深圳舒美齿科（南山店）',
		44040096: '珠海泊菲特口腔门诊部',
		44040097: '珠海雅博士口腔门诊部',
		44050071: '汕头德瑞口腔门诊部',
		44060067: '佛山拜伦口腔门诊部',
		44070069: '蓬江农世越口腔门诊',
		44080090: '湛江德澳口腔',
		44080091: '湛江致美口腔医院',
		44080092: '湛江市王成口腔诊所',
		44090070: '茂名致美口腔医院',
		44130068: '惠城斯迈尔口腔门诊',
		44150095: '陆丰德康口腔',
		44170087: '阳江华康口腔医院',
		44170088: '阳江雅博口腔门诊部',
		44170089: '阳江圣健口腔门诊部',
		44190057: '好佰年口腔（东城店）',
		44190058: '好佰年口腔（虎门店）',
		44190059: '东莞市寮步莞大口腔',
		44190060: '东莞常平爱康口腔门诊部',
		44190061: '东莞大岭山天使口腔门诊部',
		44190062: '东莞厚街魅力天使口腔门诊部',
		44190063: '东莞天使口腔医院',
		44190064: '东莞樟木头天使口腔诊所',
		44190065: '东莞长安爱雅健口腔门诊部',
		44190066: '东莞长安天使口腔门诊部',
		44200093: '友佳牙科诊所',
		44200094: '中山市西区友佳口腔门诊部东区店',
		62010053: '佳龙口腔',
		62010055: '西固区牙王国数字化口腔门诊部',
		62010056: '城关区广场南路牙王国数字化口腔门诊部',
		62070054: '李占伟口腔诊所',
		120100631: '天津河西区优诺佳口腔门诊部',
		130100184: '栾城固德口腔门诊部',
		130100185: '石家庄德贝口腔门诊部',
		130100186: '桥西刘树涛口腔诊所',
		130100187: '桥西区王曼英口腔诊所',
		130100188: '石家庄和协口腔医院',
		130100189: '石家庄玉芝口腔门诊部',
		130100190: '石家庄市裕华区康民口腔诊所',
		130100191: '裕华重光康民口腔诊所',
		130100192: '石家庄牙博士口腔医院',
		130100195: '桥西雅洁口腔诊所',
		130100198: '石家庄牙博士口腔医院管理有限公司新华青云口腔门诊部',
		130100201: '石家庄美年美华口腔医疗管理有限公司桥西口腔门诊部',
		130100202: '石家庄和睦健康管理咨询有限公司裕华口腔诊所',
		130100203: '石家庄和睦健康管理咨询有限公司长安口腔诊所',
		130200193: '迁安精典口腔诊所',
		130200200: '唐山牙博士优牙口腔门诊部',
		130300183: '秦皇岛中易口腔医疗有限公司第一诊所',
		130300199: '北戴河新区贝齿口腔诊所',
		130400176: '郝光信口腔诊所',
		130400177: '焦斌口腔(焦学斌口腔诊所)',
		130400178: '众欢口腔诊所',
		130500194: '程浩亮口腔诊所',
		130600174: '保定白沟新城圣恩口腔诊所',
		130600175: '保定市竞秀区思美口腔诊所',
		131000180: '廊坊爱嘉口腔门诊部',
		131000181: '三河束贝紫丁香口腔门诊部',
		131000182: '香河王建华口腔诊所',
		131000196: '三河阳光树口腔门诊部',
		131000197: '三河编贝紫丁香口腔门诊部',
		131100179: '桃城瑞尔口腔诊所',
		140100551: '太原幸福里口腔（孟繁涛口腔诊所）',
		140100552: '太原小店博凡口腔门诊部',
		140100553: '太原吴红瑞口腔诊所',
		140100554: '山西盛大齿科医院',
		140100555: '太原市迎泽区白翔口腔诊所',
		140100562: '太原市小店区美齿诊所',
		140100563: '太原摩尔口腔门诊部',
		140100564: '太原恒月口腔门诊部',
		140300556: '现代齿科(吴志会口腔诊所)',
		140400558: '长治亿大口腔医院',
		140400559: '长治和谐口腔医院',
		140400560: '长治长兴口腔医院',
		140400561: '长治众雅口腔门诊部',
		140600550: '柳振华口腔诊所',
		140700547: '灵石县董慧斌口腔诊所',
		140700548: '博尔口腔门诊部',
		140800557: '克明口腔诊所',
		141000549: '洪洞红十字（乐德斯）口腔门诊部',
		150100457: '呼和浩特亚非口腔门诊部',
		150100458: '至美口腔门诊部',
		150200433: '包头麒麟口腔医院',
		150200434: '九原区王鑫口腔诊所',
		150200435: '昆区菅海宽口腔诊所',
		150200436: '包头市贝特瑞尔口腔有限公司第四分公司',
		150200437: '稀土高新区义隆牙博士口腔门诊部',
		150200438: '青山凯斯口腔门诊部',
		150200439: '青山区植诺口腔门诊部',
		150200461: '东河区史伟口腔诊所',
		150300459: '吕卓口腔诊所',
		150400440: '赤峰市德医美口腔医院',
		150600441: '鄂尔多斯市青蕾口腔医疗有限公司口腔诊所',
		150600442: '东胜区尹美英口腔诊所',
		150600443: '东胜区志强口腔诊所',
		150600444: '鄂尔多斯汉生口腔医院',
		150600445: '鄂尔多斯市奥麒口腔医院',
		150600446: '内蒙古汉林口腔种植医院有限公司口腔诊所分公司',
		150600447: '奇布拉格口腔诊所',
		150600448: '王娜口腔诊所',
		150600449: '殷慧文口腔病防治所',
		150600450: '奥麒口腔医院康巴什门诊部',
		150600451: '同心口腔门诊部',
		150600452: '鄂尔多斯市伊金霍洛旗潘慧聪口腔诊所',
		150600453: '鄂尔多斯伊金霍洛旗高鹃口腔诊所',
		150600454: '伊金霍洛旗阿镇杭氏口腔门诊部',
		150600455: '伊金霍洛旗阿镇惠文口腔病防治所',
		150600456: '伊金霍洛旗牙之家口腔门诊部',
		150600460: '内蒙古惠文口腔门诊部',
		210100402: '沈阳大东全好百乐口腔门诊部有限公司（全好沈阳百乐）',
		210100403:
			'沈阳全好口腔医疗门诊服务有限公司大东东顺口腔门诊部（全好沈阳东顺）',
		210100404:
			'沈阳全好口腔医疗门诊服务有限公司大东吉祥口腔门诊部（全好沈阳吉祥）',
		210100405:
			'沈阳全好口腔医疗门诊服务有限公司大东联合路口腔门诊部（全好沈阳联合路）',
		210100406:
			'沈阳全好口腔医疗门诊服务有限公司大东桃原口腔门诊部（全好沈阳桃原）',
		210100407: '沈阳大东北海沈美口腔门诊部',
		210100408: '沈阳大东东顺沈美口腔门诊部',
		210100409: '沈阳大东江东沈美口腔门诊部',
		210100410: '沈阳欢乐英卓医院管理有限公司和平仁爱门诊部',
		210100411:
			'沈阳全好口腔医疗门诊服务有限公司和平三好街口腔门诊部（全好沈阳三好街）',
		210100412:
			'沈阳全好口腔医疗门诊服务有限公司太原南街口腔门诊部（全好沈阳太原南街）',
		210100413: '沈阳和平经纬沈美口腔门诊部',
		210100414: '沈阳贝斯特医院管理有限公司皇姑珠江街口腔门诊部',
		210100415:
			'沈阳全好口腔医疗门诊服务有限公司昆山西路口腔门诊部（全好沈阳昆山西路）',
		210100416: '沈阳欢乐英卓医院管理有限公司浑南欢乐仁爱口腔门诊部',
		210100417: '沈阳欢乐英卓医院管理有限公司沈河欢乐仁爱口腔医院',
		210100418: '沈阳欢乐英卓医院管理有限公司沈河十一纬路仁爱口腔门诊部',
		210100419:
			'沈阳全好口腔医疗门诊服务有限公司沈河奉天街口腔门诊部（全好沈阳奉天街）',
		210100420:
			'沈阳全好口腔医疗门诊服务有限公司沈河万寿寺口腔门诊部（全好沈阳万寿寺）',
		210100421: '沈阳沈河顺城沈美口腔门诊部',
		210100422: '沈阳沈河小西沈美口腔门诊部',
		210100423: '沈阳沈河顺通口腔门诊部',
		210100424:
			'沈阳全好口腔医疗门诊服务有限公司铁西保工口腔门诊部（全好沈阳保工）',
		210100425: '全好沈阳铁西兴顺',
		210100426:
			'沈阳全好口腔医疗门诊服务有限公司铁西艳粉口腔门诊部（全好沈阳艳粉）',
		210100427:
			'沈阳全好口腔医疗门诊服务有限公司铁西艳欣口腔门诊部（全好沈阳艳欣）',
		210100428:
			'沈阳全好口腔医疗门诊服务有限公司铁西云峰口腔门诊部（全好沈阳云峰）',
		210100429: '沈阳铁西全好贵佳和口腔门诊部有限公司（全好沈阳贵佳和）',
		210100430: '沈阳英卓医院管理有限公司新民欢乐仁爱口腔门诊部',
		210200374:
			'大连甘井子红旗东路全好口腔门诊部有限公司富强路口腔诊所（全好大连富强路）',
		210200375:
			'大连甘井子红旗东路全好口腔门诊部有限公司千山路口腔诊所（全好大连千山路）',
		210200376:
			'大连甘井子红旗东路全好口腔门诊部有限公司湾家口腔诊所（全好大连湾家）',
		210200377:
			'大连甘井子红旗东路全好口腔门诊部有限公司文体街口腔诊所（全好大连文体街）',
		210200378:
			'大连甘井子红旗东路全好口腔门诊部有限公司辛吉街口腔诊所（全好大连辛吉街）',
		210200379:
			'大连甘井子红旗东路全好口腔门诊部有限公司迎客路口腔诊所（全好大连迎客路）',
		210200380:
			'大连甘井子红旗东路全好口腔门诊部有限公司（全好大连红旗东路）',
		210200381: '大连沙河口区锦绣路全好口腔门诊有限公司（全好大连锦绣路）',
		210200382: '大连沙河口区西南路全好口腔门诊有限公司（全好大连西南路）',
		210200383:
			'大连沙河口区中山公园全好口腔门诊有限公司（全好大连中山公园）',
		210200384:
			'大连沙河口区中山公园全好口腔门诊有限公司北甸街口腔诊所（全好大连北甸街）',
		210200385:
			'大连沙河口区中山公园全好口腔门诊有限公司香沙街口腔诊所（全好大连香沙街）',
		210200386:
			'大连沙河口区中山公园全好口腔门诊有限公司星海口腔诊所（全好大连星海）',
		210200387:
			'大连沙河口区中山公园全好口腔门诊有限公司兴工街口腔诊所（全好大连兴工街）',
		210200388: '大连西岗区黄河路全好口腔门诊有限公司（全好大连黄河路）',
		210200389: '大连全好口腔门诊有限公司（全好大连）',
		210200390: '大连全好口腔门诊有限公司丹东街口腔诊所（全好大连丹东街）',
		210200391: '大连全好口腔门诊有限公司解放路口腔诊所（全好大连解放路）',
		210200392: '大连全好口腔门诊有限公司山屏街口腔诊所（全好大连屏街）',
		210200393:
			'大连全好医疗门诊服务有限公司七贤路口腔诊所（全好大连七贤路）',
		210400397: '抚顺市顺城区顺成悦美口腔门诊部',
		210500373: '桓仁田锋口腔门诊部',
		210600394: '东港高淑芳口腔诊所',
		210600395: '丹东市元宝区王艳华口腔诊所',
		210600396: '丹东唯美医疗服务有限公司中富口腔诊所',
		210800431: '杨秋玲口腔个体诊所',
		210800432: '盖州奥新全民口腔医院',
		211000399: '刘艳口腔诊所',
		211100400: '兴隆台奥泰口腔门诊部',
		211100401: '盘锦市兴隆台区包稳口腔诊所',
		211400398: '葫芦岛市龙港区骏辉口腔诊所',
		220200313: '吉林磐石包大伟牙科医院',
		220500315: '通化市东昌区金博康口腔门诊部',
		220600312: '白山市浑江区雅康口腔诊所',
		220700314: '松原精技口腔门诊部',
		230100248: '哈尔滨卓尔口腔门诊部',
		230100249: '哈尔滨啄木鸟口腔门诊部',
		230100252: '哈尔滨拜博口腔（和平路门诊）',
		230100253: '哈尔滨市南岗区金爵口腔门诊部',
		230100254: '哈尔滨德诚明晋口腔门诊部',
		230200250: '齐齐哈尔守平口腔医院',
		230200251: '铁锋区鹤翔口腔门诊部',
		230600245: '圣光口腔门诊部',
		230600246: '大庆市萨尔图区优根口腔门诊部',
		230600247: '丽花口腔门诊部',
		230600255: '大庆市晨光口腔门诊部',
		310100589: '上海罗缘口腔门诊部',
		310100590: '上海尚雅口腔门诊部',
		310100591: '上海皇欣口腔门诊部',
		310100592: '瑞泰口腔(前滩旗舰店)',
		310100593: '上海雅适美口腔门诊部',
		310100594: '上海艾维口腔门诊部',
		310100595: '上海佳沪口腔门诊部',
		310100596: '上海圣康口腔门诊部',
		310100597: '上海美乐口腔门诊部',
		310100598: '上海百仕口腔门诊部',
		310100599: '上海宇佳口腔门诊部',
		310100600: '上海瑞泰永源口腔门诊部',
		320100321: '南京六合值德口腔门诊部',
		320100342: '南京玉蕙口腔门诊部',
		320100350: '南京贝鹤雅康口腔医院',
		320200333: '(金贝齐齿科)无锡贝齐口腔门诊部',
		320200334: '无锡爱牙口腔门诊部',
		320200335: '无锡雅仕康口腔门诊部',
		320200336: '(金贝齐齿科)无锡金汇口腔门诊部',
		320200343: '江阴弘和口腔门诊部',
		320300348: '徐州泉山博奥口腔门诊部',
		320400316: '常州贺冰口腔诊所',
		320400317: '常州铭齿口腔门诊部',
		320400318: '常州鑫镁口腔门诊部',
		320400345: '常州市东太平洋口腔门诊部',
		320400346: '常州太平洋口腔门诊部',
		320400347: '常州南部太平洋口腔门诊部',
		320500327: '昆山瑞德口腔门诊部',
		320500328: '昆山市玉山镇嘉丽口腔门诊部',
		320500329: '太仓益贝佳口腔门诊部',
		320500330: '苏州工业园区舒迪凯口腔诊所',
		320500349: '张家港玉蕙口腔医院',
		320500351: '昆山益达口腔门诊部',
		320500352: '姑苏区韩欣义口腔诊所',
		320500353: '张家港皓康口腔门诊部',
		320600322: '南通玉蕙口腔医院',
		320600323: '海门思迈尔口腔门诊部',
		320600324: '啄木鸟口腔门诊部',
		320600325: '如皋牙博士口腔门诊部',
		320600326: '通州牙卫士口腔门诊部',
		320700320: '连云港美年口腔',
		320800319: '雅美口腔门诊部',
		320900338: '射阳兴华口腔门诊部',
		320900339: '盐城倍美永康口腔门诊有限公司倍美永康口腔门诊部',
		320900344: '盐城通植口腔门诊部',
		320900354: '盐城安佳倍美口腔门诊部',
		321000340: '高邮新时代口腔门诊部',
		321000341: '江都区仙女镇前程口腔门诊部',
		321200331: '泰州北极星口腔门诊部',
		321200332: '玲珑口腔门诊部',
		321300337: '泗阳县口腔医院',
		330100651: '品凯口腔彭埠门诊',
		330100652: '品凯口腔明月门诊',
		330100653: '品凯口腔固陵门诊',
		330100654: '品凯口腔拱北门诊',
		330100655: '品凯口腔登云门诊',
		330100656: '品凯口腔祥符门诊',
		330100657:
			'杭州全好健康管理有限公司湖墅南路口腔诊所（全好杭州湖墅南路）',
		330100658: '杭州全好健康管理有限公司信义坊口腔诊所（全好杭州信义坊）',
		330100659: '杭州同好健康管理有限公司（全好杭州同好口腔）',
		330100660: '品凯口腔丁桥门诊',
		330100661: '品凯口腔博皓门诊',
		330100662: '品凯口腔凤起门诊',
		330100663: '杭州登丝特口腔门诊部',
		330100664: '杭州云齿口腔医院',
		330100665: '品凯口腔盈佳门诊',
		330100666: '杭州拜博口腔（德力西门诊）',
		330100667: '品凯口腔晴川门诊',
		330100668: '品凯口腔丰潭门诊',
		330100669: '杭州全好健康管理有限公司莲花街口腔诊所（全好杭州莲花街）',
		330100670: '杭州全好健康管理有限公司学院路口腔诊所（全好杭州学院路）',
		330100671: '杭州市西湖区全好口腔门诊部（全好杭州西湖）',
		330100672: '杭州微牙口腔门诊部',
		330100673: '品凯口腔华丰门诊',
		330100674: '品凯口腔旗舰总部',
		330100675: '杭州植得口腔门诊部',
		330100676: '品凯口腔品洁门诊',
		330100677: '杭州余杭非凡口腔门诊部',
		330100678: '杭州牙安口腔医疗管理有限公司余杭东湖中路口腔诊所',
		330100690: '杭州德贝艾尔口腔门诊部',
		330100692: '杭州贝齿特微笑口腔诊所',
		330100693: '品凯口腔东站数字化门诊',
		330100694: '品凯口腔桃源门诊',
		330100695: '杭州城星美奥口腔门诊部',
		330100701: '品凯口腔亮贝门诊',
		330100702: '杭州萧山匠心德贝金境口腔门诊部',
		330200681: '宁波鄞州协禾口腔门诊部',
		330200682: '宁波鄞州牙科医院',
		330300688: '李永玮口腔诊所',
		330300696: '温州奥齿泰口腔门诊部',
		330300698: '温州瓯海爱牙故事口腔门诊部',
		330300699: '温州鹿城爱牙故事口腔门诊部',
		330300700: '温州鹿城爱牙故事新城口腔门诊部',
		330400679: '平湖市复尔口腔门诊部',
		330600683: '绍兴越中口腔医院',
		330700691: '武义微笑口腔诊所',
		330700697: '义乌百奥口腔门诊部',
		330900689: '普陀维乐口腔门诊部',
		331000684: '临海市汤歆影口腔诊所',
		331000685: '天台余家口腔门诊部',
		331000686: '温岭泽国安然口腔门诊部',
		331000687: '仙居县博雅口腔门诊部',
		331100680: '龙泉新华口腔诊所',
		360100361: '高新尚吉慧口腔门诊部',
		360100362: '南昌美伦口腔门诊部',
		360200358: '昌江区亚齐口腔门诊部',
		360300363: '安源区修安口腔门诊部',
		360300364: '安源修安口腔门诊部金典城分部',
		360300365: '安源好牙一生口腔诊所',
		360400359: '九江市奇洋口腔门诊部（普通合伙）',
		360400360: '浔阳区奇洋庐峰口腔门诊部（普通合伙）',
		360500369: '新余市章根训口腔医学中心',
		360600371: '江平军口腔诊所',
		360600372: '江西省鹰潭市尚品口腔门诊部',
		360700356: '赣州市管江口腔门诊部',
		360700357: '赣州阳光口腔门诊部',
		360900370: '宜春辉荣口腔门诊部',
		361000355: '抚州百合口腔门诊部',
		361100366: '广信美唯阳光口腔门诊部',
		361100367: '上饶市维乐阳光口腔医院',
		361100368: '上饶信州美维阳光口腔门诊部',
		370100471: '济南可恩口腔医院和谐广场分院',
		370100472: '莱芜牙博士口腔清水湾门诊部',
		370100473: '莱芜牙博士口腔胜利路门诊部',
		370100474: '莱芜景先军口腔诊所',
		370100475: '济南可恩口腔医院',
		370100476: '历城路业舜天口腔诊所',
		370100477: '历下毅卉口腔门诊部',
		370100478: '济南可恩口腔国际会展门诊部',
		370100479: '济南可恩口腔医院历下门诊部',
		370100480:
			'济南全好健康管理有限公司历下朝山街口腔诊所（全好济南朝山街）',
		370100481:
			'济南全好健康管理有限公司历下东关大街口腔门诊部（全好济南东关大街）',
		370100482: '高新雅辰口腔门诊部',
		370100483: '市中万康口腔门诊部',
		370100535: '济南亿佳医疗管理有限公司历城华龙路口腔诊所',
		370100541: '济南高新晨阳口腔门诊部',
		370200497: '城阳徐环环雅新口腔诊所',
		370200498: '即墨瑞康口腔门诊部',
		370200499: '即墨顺康口腔门诊部',
		370200500: '市北雅乐康口腔诊所',
		370200501: '青岛可恩口腔李沧院',
		370200502:
			'青岛全好健康管理有限公司李沧区巨峰路口腔门诊部（全好青岛巨峰路）',
		370200503:
			'青岛全好健康管理有限公司李沧区南崂路口腔门诊部（全好青岛南崂路）',
		370200504:
			'青岛全好健康管理有限公司李沧区夏庄路口腔门诊部（全好青岛夏庄路）',
		370200505:
			'青岛全好健康管理有限公司李沧区振华路口腔门诊部（全好青岛振华路）',
		370200506: '李沧牙博士口腔门诊部',
		370200507: '李沧百佳口腔诊所',
		370200508: '青岛可恩口腔医院市北分院',
		370200509:
			'青岛全好健康管理有限公司市北区辽宁路口腔门诊部（全好青岛辽宁路）',
		370200510:
			'青岛全好健康管理有限公司市北区延吉路口腔诊所（全好青岛延吉路）',
		370200511:
			'青岛全好健康管理有限公司市北区长春路口腔诊所（全好青岛长春路）',
		370200512: '青岛牙博士口腔医院',
		370200513: '市北优家口腔门诊部',
		370200514: '青岛可恩口腔医院市南分院',
		370200515:
			'青岛全好健康管理有限公司市南区江西路口腔门诊部（全好青岛江西路）',
		370200534: '青岛和爱慈医疗管理有限公司市北艾诺口腔诊所',
		370200538: '青岛瑞齿口腔门诊部',
		370300530: '淄博高青瑞佳口腔医院',
		370300531: '山东金良口腔诊所（华光路店）',
		370400536: '滕州万康口腔门诊部',
		370400540: '枣庄市澳美口腔',
		370500468: '东营同泰口腔医院',
		370500469: '晓倩口腔诊所',
		370500470: '金伟牙科诊所',
		370600529: '龙口陈学刚口腔诊所',
		370600539: '烟台爱博口腔门诊部',
		370600544: '蓬莱美达口腔门诊部',
		370700521: '高密丁伟口腔诊所',
		370700522: '潍坊坊子宏垣口腔医院',
		370700523: '郑氏口腔坊子店（坊子致雅口腔门诊部）',
		370700524: '郑氏口腔医院',
		370700525: '郑氏口腔四平路店（奎文邢轲口腔诊所）',
		370700526: '青州东圣水医院',
		370700527: '郑氏口腔南柴店（寿光井旭口腔诊所）',
		370700528: '郑氏口腔建新街口腔诊所',
		370700542: '郑氏口腔潍城店（潍城登特口腔门诊部）',
		370800484: '任城李凤华口腔科诊所',
		370800485: '王琦口腔门诊部',
		370800486: '汶上德柱口腔门诊部',
		370900517: '泰安市泰山区洪元口腔诊所',
		370900518: '泰安雅玉口腔医院',
		370900519: '新泰口腔医院',
		371000520: '威海舒好口腔门诊部',
		371000543: '环翠区李欢口腔诊所',
		371100516: '东港世同口腔门诊部',
		371300495: '临沂兰山晓波口腔门诊部',
		371300496: '临沂刘子斌口腔医疗有限公司口腔门诊部',
		371300537: '临沂优贝口腔医疗有限公司口腔门诊部',
		371300546: '临沂兰山银座牙客口腔门诊部',
		371400466: '德州康韵口腔诊所',
		371400467: '禹城市孙建涛口腔诊所',
		371500487: '聊城口腔医院',
		371500488: '聊城恒丰口腔诊所',
		371500489: '聊城惠尔口腔门诊部',
		371500490: '众望口腔诊所',
		371500491: '茌平颐正口腔医院',
		371500492: '聊城美冠口腔',
		371500493: '聊城临清市鼎正口腔',
		371500494: '吴清华口腔诊所',
		371500532: '东阿县新城颐正口腔门诊部',
		371500545: '聊城颐正口腔门诊部',
		371600464: '滨城曲站峰口腔诊所',
		371600465: '滨城洁仕口腔门诊部',
		371600533: '惠民雅之美口腔诊所',
		410100226: '金水爱尔雅口腔门诊部',
		410100227: '郑东康庄路天美口腔门诊部',
		410100229: '二七碧云赛雯口腔门诊部',
		410100231: '二七盛禾口腔门诊部',
		410200205: '开封新区刘文利口腔诊所',
		410300206: '涧西维乐口腔门诊部',
		410300207: '洛阳维乐口腔（沃德店）',
		410300208: '洛阳维乐口腔（凌宇犀地店）',
		410300209: '洛阳维乐口腔（七一路店）',
		410300210: '洛阳维乐口腔（春都路店）',
		410300211: '金诺口腔门诊部',
		410300212: '健康西路斯麦尔口腔门诊部',
		410300230: '洛阳六和口腔医院',
		410300232: '七一路九龙口腔门诊部',
		410300233: '延安路九龙口腔门诊部',
		410300234: '八一路九龙口腔门诊部',
		410300235: '洛宁九龙口腔门诊部',
		410300236: '洛宁九龙口腔诊所',
		410300237: '永泰街九龙口腔门诊部',
		410300238: '太康路九龙口腔门诊部',
		410300239: '上阳路九龙口腔门诊部',
		410300240: '浅井南路九龙口腔门诊部',
		410300241: '瀍河九龙口腔门诊部',
		410300242: '英才路九龙口腔门诊部',
		410300243: '周山路九龙口腔门诊部',
		410300244: '珠江路九龙口腔门诊部',
		410400214: '湛河贝诺口腔门诊部',
		410500204: '汤阴越峰门诊部',
		410700220: '益尔口腔（新乡牧野岳晓玲口腔诊所）',
		410700221: '长垣京典口腔',
		410900215: '濮阳爱之牙口腔门诊部',
		410900216: '濮阳光华口腔门诊部',
		411000224: '许昌小叮当口腔门诊部（小叮当连锁）',
		411000225: '长葛刘书攀口腔诊所（小叮当连锁）',
		411100213: '舒适美口腔（源汇许克哲口腔诊所）',
		411200217: '李秀春口腔诊所',
		411200218: '三门峡泊菲特口腔门诊部',
		411400219: '睢阳新城蓝天口腔门诊部',
		411500222: '伢牙齿科口腔门诊部（平桥店）',
		411500223: '伢牙齿科口腔门诊部（浉河店）',
		411700228: '驿城区美齿口腔门诊部',
		420100277: '武汉德韩摩尔城口腔门诊部',
		420100278: '武汉良依口腔花桥门诊部',
		420100279: '武汉德韩凯德西城口腔门诊部',
		420100280: '武汉青山东华口腔门诊部',
		420100281: '武汉皓诺口腔门诊部',
		420100288: '朗歌口腔武昌店',
		420100289: '朗歌口腔江汉店',
		420100291: '武汉雅立德口腔门诊部',
		420300275: '十堰市张中勇口腔门诊部',
		420500284: '宜昌高新区景玺口腔诊所',
		420500285: '宜昌高新区益齿生口腔门诊部',
		420500286: '宜昌高新区汪万年东山口腔门诊部',
		420500287: '宜昌市西陵区学院街汪万年口腔门诊部',
		420500290: '宜昌市伍家岗区同仁口腔诊所',
		420600283: '襄阳时代永恒口腔立业路门诊部',
		420800270: '钟祥小灰兔口腔门诊',
		420800271: '钟祥恩吉口腔门诊部',
		420800272: '钟祥海峰口腔诊所',
		421000273: '沙市区黄兵兵口腔诊所',
		421000274: '沙市区荆楚固德口腔诊所',
		421100268: '红安县思迈尔口腔门诊部',
		421100269: '浠水美维中山口腔门诊部',
		421200282: '佳龙口腔诊所',
		421300276: '随州东华口腔门诊部',
		422800256: '恩施咿呀口腔巴东诊所',
		422800257: '恩施咿呀口腔福星诊所',
		422800258: '恩施咿呀口腔舞阳诊所',
		422800259: '恩施咿呀口腔金桂诊所',
		422800260: '恩施咿呀口腔鹤峰诊所',
		422800261: '恩施咿呀口腔建始诊所',
		422800262: '恩施咿呀口腔来凤诊所',
		422800263: '恩施咿呀口腔利川诊所',
		422800264: '恩施咿呀口腔宣恩诊所',
		430100304: '浏阳北盛罗记牙科诊所',
		430100308: '泉塘亚雅口腔门诊部',
		430100309: '长沙县泉塘街道牙雅口腔门诊部',
		430100310: '芙蓉亚雅口腔门诊部',
		430100311: '长沙市岳麓区海云口腔门诊部',
		430200305: '株洲柏霖口腔门诊有限公司芦淞门诊部',
		430200306: '株洲湘安口腔医院',
		430400294: '衡阳奥利齿口腔诊所',
		430400295: '衡阳谢氏口腔门诊部',
		430500299: '邵阳雅贝康口腔门诊部',
		430500300: '隆回县齿美康口腔门诊部',
		430600301: '雅美佳口腔诊所（蒋志韬口腔诊所）',
		430600302: '雅悦口腔（周放口腔诊所）',
		430700292: '汉寿周大桂口腔科诊所',
		430700293: '常德益达口腔诊所',
		430800303: '张家界博瑞口腔门诊部',
		431200296: '怀化赖尔口腔门诊部',
		431300297: '康馨口腔门诊',
		431300298: '新化优贝口腔',
		431300307: '娄底爱牙邦口腔医院',
		440100101: '广州优越会口腔门诊部',
		440100111: '研科口腔门诊部',
		440100114: '广州德道口腔门诊部',
		440100115: '广州美蒂斯口腔门诊部',
		440100116: '广州鹏爱口腔',
		440100118: '华医口腔门诊部',
		440100120: '广州心一口腔门诊部',
		440100121: '华晨达宝口腔门诊',
		440100124: '研科竹料口腔门诊部',
		440100127: '广州德尚口腔门诊部',
		440200117: '康牙口腔门诊部',
		440300109: '贝齿美口腔门诊部',
		440300110: '储成华口腔诊所',
		440300112: '深圳南州罗岗口腔门诊部',
		440300113: '深圳南州李王胜口腔诊所',
		440300119: '希奥口腔门诊部',
		440300123: '深圳友恩口腔诊所',
		440300125: '深圳艺恩口腔门诊部（沃思顿口腔）',
		440300126: '深圳弘和口腔（欧瑞口腔门诊部）',
		440300129: '岚世纪口腔（择尔店）',
		440300130: '思杰亚洲深圳发展有限公司珮斐口腔门诊部',
		440400102: '珠海奥尔口腔门诊部',
		440400108: '珠海金湾拜恩口腔门诊',
		440600100: '佛山南海邓志远口腔诊所',
		440600104: '佛山拜伦口腔南庄门诊部',
		440600105: '佛山拜伦口腔普君门诊部',
		440600107: '佛山拜顿口腔杨文军诊所',
		441900103: '好佰年口腔（东莞寮步店）',
		441900122: '好佰年口腔（虎门丰泰店）',
		441900128: '东莞厚街宝屯唯医口腔门诊部',
		445300106: '云浮拜伦口腔门诊部',
		450100141: '南宁辉宇口腔门诊部',
		450100142: '牙典口腔（南宁黎敏玲口腔诊所）',
		450100143: '南宁皓乐口腔诊所',
		450100144: '广西洋紫荆口腔茶花园店',
		450100145: '超然口腔（南宁唐宓口腔诊所）',
		450100146: '南宁李世平口腔诊所',
		450100147: '南宁六维口腔门诊部',
		450100148: '南宁象雅口腔门诊部',
		450100149: '万象口腔（南宁欧永富口腔诊所）',
		450100150: '西博雅口腔（南宁叶佐琳口腔诊所）',
		450100151: '南宁蓝天口腔门诊部',
		450100152: '南宁小骆驼口腔门诊部',
		450100153: '广西洋紫荆口腔东沟岭店',
		450100158: '南宁农群口腔诊所',
		450100159: '南宁蓝天口腔医院有限公司金川口腔门诊部',
		450100160: '南宁蓝天口腔医院有限公司嘉和城口腔门诊部',
		450200136: '柳州美齿口腔门诊部',
		450200137: '皓迪口腔门诊部',
		450200138: '柳州华喜口腔门诊部',
		450200139: '柳州品植口腔门诊部',
		450200140: '喜天丽口腔门诊部',
		450300134: '桂林美皓口腔门诊部',
		450300135: '桂林刘永寿口腔诊所',
		450400154: '梧州蓝天口腔医院',
		450500132: '北海爱齿尔口腔门诊部',
		450700157: '蓝天口腔',
		450900155: '广西恒兴口腔门诊',
		450900156: '黄远宁口腔诊所',
		451000131: '田东县苏医生口腔医院',
		451400133: '崇左恒美口腔诊所',
		460100168: '海南鼎点口腔海口东湖分院',
		460100169: '海南鼎点口腔海口国贸总院',
		460100170: '海口龙华德恩口腔门诊部',
		460100171: '陈新口腔门诊部',
		460100172: '海南光华口腔医院(海医)',
		460200173: '海南鼎点口腔三亚下洋田分院',
		500100703: '瑞泰久悦齿科巴南店',
		500100704: '瑞泰久悦口腔龙湖源著门诊',
		500100705: '江北赵慧口腔诊所',
		500100706: '重庆汇恩医院江北口腔门诊部',
		500100707: '邓亚宁口腔诊所',
		500100708: '重庆红十字卫东口腔诊所',
		500100709: '重庆佳运口腔门诊',
		500100710: '重庆正舸口腔（天地店）',
		500100711: '重庆正舸口腔（南坪店)',
		500100712: '邵学东口腔诊所',
		500100713: '万州品善口腔门诊部',
		500100714: '思阳口腔九龙坡门店',
		500100715: '思阳口腔渝北门店',
		500100716: '重庆亿佰口腔门诊部',
		500100717: '重庆金开八益口腔门诊部',
		500100718: '渝北水晶八益牙科门诊部',
		500100719: '南岸八益口腔门诊部',
		500100720: '沙坪坝八益口腔门诊部',
		500100721: '渝中区大坪八益牙科门诊部',
		500100722: '重庆正舸口腔有限公司南岸金辉广场口腔诊所',
		510100601: '天府新区华阳青禾口腔',
		510100602: '成都武侯贝壳口腔门诊部',
		510100603: '海博牙科',
		510100607: '成都伯菲口腔',
		510100608: '成都金牛菁品口腔门诊部',
		510100611: '思微尔口腔成都店',
		510100612: '成都双流唐之美口腔门诊部（唐牙科）',
		510100613: '成都武侯圣亚口腔(唐牙科)',
		510100614: '成都武侯唐牙乐口腔(唐牙科)',
		510100615: '成都锦江一唐牙科诊所',
		510100616: '成都天府新区唐艺美口腔（唐牙科）',
		510100617: '武侯三唐口腔诊所(唐牙科)',
		510100618: '成华尔康诊所（唐牙科）',
		510100619: '锦江唐牙科口腔诊',
		510100620: '武侯区二唐牙科诊所',
		510100621: '成华一唐牙科口腔诊所',
		510100622: '青羊区二唐牙科门诊部',
		510100623: '成都武侯唐建华口腔（唐牙科）',
		510100624: '青羊区唐牙科门诊部',
		510100625: '武侯区德蓉口腔诊所(唐牙科)',
		510100626: '成都市锦江区唐建华口腔(唐牙科)',
		510100627: '成都高新区唐建华口腔（唐牙科）',
		510100628: '成都武侯老唐牙科口腔诊所',
		510100629: '武侯圣元口腔（唐牙科）',
		510100630: '武侯区纯福雅乐口腔门诊部',
		510700609: '思微尔口腔三台店',
		510700610: '思微尔口腔绵阳店',
		511300604: '远大口腔顺庆分院',
		511300605: '远大口腔北城分院',
		511300606: '南充博瑞口腔诊所',
		520100161: '白云艺宁口腔诊所',
		520100162: '信义口腔',
		520100163: '贵阳市南明区一牙兔口腔诊所（凯丰牙科）',
		520100165: '贵阳拜尔口腔（枣山路门诊）',
		520100166: '贵阳拜尔口腔（金辉门诊）',
		520100167: '贵阳拜尔口腔（花果园门诊）',
		520200164: '六盘水水城口腔医院',
		530100635: '尚爱韩美口腔环城南路院区',
		530100636: '官渡艾洁口腔门诊部',
		530100637: '云南德普医疗有限公司双桥路口腔门诊部',
		530100638: '尚爱韩美口腔北京路院区',
		530100639: '昆明先行口腔诊所有限公司东庄诊所',
		530100640: '齿盾口腔五华馨悦尚居店',
		530100641: '五华长鹏口腔诊所',
		530100642: '长鹏口腔（丽阳星城）',
		530100643: '长鹏口腔（凯旋店）',
		530100644: '长鹏口腔（兴苑店）',
		530100645: '长鹏口腔（香樟俊园店）',
		530100646: '长鹏口腔（世纪城店）',
		530100647: '尚爱韩美口腔润城店',
		530100649: '齿盾口腔泰旸新城店（宗宏口腔）',
		530100650: '昆明昆德口腔诊所',
		530300648: '陆良蓝天诊所',
		532500634: '蒙自吉祥口腔门诊部',
		532600632: '文山拜尔口腔门诊部',
		532900633: '思美齐口腔',
		610100571: '西安莲湖皓齿口腔诊所',
		610100572: '西安嘉佳京华口腔',
		610100573: '陕西皓月口腔医疗有限公司',
		610100574: '陕西尚甫商贸有限公司雁塔口腔诊所',
		610100575: '西安雁塔刘津茹口腔诊所',
		610100576: '西安雁塔张绍山口腔诊所',
		610100577: '西安雁塔曲江强森医院',
		610100578: '西安长安琅琅口腔门诊部',
		610100579: '西安长安孙艳梅口腔诊所',
		610100580: '西安长安为民口腔门诊部',
		610100583: '西安未央美琅口腔诊所',
		610100584: '禾马西安口腔医疗有限公司雁塔口腔诊所',
		610100585: '西安未央铁齿铜牙口腔',
		610100586: '西安德美口腔医院管理有限公司曲江新区口腔门诊部',
		610100587: '西安未央伯牙利口腔诊所',
		610100588: '西安莲湖马克口腔诊所',
		610200569: '王益区博德口腔门诊部',
		610300566: '宝鸡市金台区小丁口腔诊所',
		610300567: '宝鸡牙卫士口腔诊所',
		610500570: '蒲城明真口腔门诊',
		610700568: '安朗口腔诊所',
		610800581: '神木市潘慧聪口腔诊所',
		610800582: '榆林口腔医院',
		610900565: '安康同仁口腔门诊部',
		630100463: '西宁华康口腔医院',
		640100462: '佳成口腔诊所'
	},
	city_list: {
		110100: '北京市',
		120100: '天津市',
		130100: '石家庄市',
		130200: '唐山市',
		130300: '秦皇岛市',
		130400: '邯郸市',
		130500: '邢台市',
		130600: '保定市',
		// 130700: '张家口市',
		// 130800: '承德市',
		// 130900: '沧州市',
		131000: '廊坊市',
		131100: '衡水市',
		140100: '太原市',
		// 140200: '大同市',
		140300: '阳泉市',
		140400: '长治市',
		// 140500: '晋城市',
		140600: '朔州市',
		140700: '晋中市',
		140800: '运城市',
		// 140900: '忻州市',
		141000: '临汾市',
		// 141100: '吕梁市',
		150100: '呼和浩特市',
		150200: '包头市',
		150300: '乌海市',
		150400: '赤峰市',
		// 150500: '通辽市',
		150600: '鄂尔多斯市',
		// 150700: '呼伦贝尔市',
		// 150800: '巴彦淖尔市',
		// 150900: '乌兰察布市',
		// 152200: '兴安盟',
		// 152500: '锡林郭勒盟',
		// 152900: '阿拉善盟',
		210100: '沈阳市',
		210200: '大连市',
		// 210300: '鞍山市',
		210400: '抚顺市',
		210500: '本溪市',
		210600: '丹东市',
		// 210700: '锦州市',
		210800: '营口市',
		// 210900: '阜新市',
		211000: '辽阳市',
		211100: '盘锦市',
		// 211200: '铁岭市',
		// 211300: '朝阳市',
		211400: '葫芦岛市',
		// 220100: '长春市',
		220200: '吉林市',
		// 220300: '四平市',
		// 220400: '辽源市',
		// 220500: '通化市',
		220600: '白山市',
		220700: '松原市',
		// 220800: '白城市',
		// 222400: '延边朝鲜族自治州',
		230100: '哈尔滨市',
		230200: '齐齐哈尔市',
		// 230300: '鸡西市',
		// 230400: '鹤岗市',
		// 230500: '双鸭山市',
		230600: '大庆市',
		// 230700: '伊春市',
		// 230800: '佳木斯市',
		// 230900: '七台河市',
		// 231000: '牡丹江市',
		// 231100: '黑河市',
		// 231200: '绥化市',
		// 232700: '大兴安岭地区',
		310100: '上海市',
		320100: '南京市',
		320200: '无锡市',
		320300: '徐州市',
		320400: '常州市',
		320500: '苏州市',
		320600: '南通市',
		320700: '连云港市',
		320800: '淮安市',
		320900: '盐城市',
		321000: '扬州市',
		// 321100: '镇江市',
		321200: '泰州市',
		321300: '宿迁市',
		330100: '杭州市',
		330200: '宁波市',
		330300: '温州市',
		330400: '嘉兴市',
		// 330500: '湖州市',
		330600: '绍兴市',
		330700: '金华市',
		// 330800: '衢州市',
		330900: '舟山市',
		331000: '台州市',
		331100: '丽水市',
		340100: '合肥市',
		// 340200: '芜湖市',
		340300: '蚌埠市',
		// 340400: '淮南市',
		340500: '马鞍山市',
		// 340600: '淮北市',
		// 340700: '铜陵市',
		340800: '安庆市',
		// 341000: '黄山市',
		341100: '滁州市',
		341200: '阜阳市',
		// 341300: '宿州市',
		341500: '六安市',
		341600: '亳州市',
		341700: '池州市',
		341800: '宣城市',
		350100: '福州市',
		// 350200: '厦门市',
		350300: '莆田市',
		350400: '三明市',
		350500: '泉州市',
		// 350600: '漳州市',
		// 350700: '南平市',
		// 350800: '龙岩市',
		// 350900: '宁德市',
		360100: '南昌市',
		360200: '景德镇市',
		360300: '萍乡市',
		360400: '九江市',
		360500: '新余市',
		360600: '鹰潭市',
		360700: '赣州市',
		// 360800: '吉安市',
		360900: '宜春市',
		361000: '抚州市',
		361100: '上饶市',
		370100: '济南市',
		370200: '青岛市',
		370300: '淄博市',
		370400: '枣庄市',
		370500: '东营市',
		370600: '烟台市',
		370700: '潍坊市',
		370800: '济宁市',
		370900: '泰安市',
		371000: '威海市',
		371100: '日照市',
		371300: '临沂市',
		371400: '德州市',
		371500: '聊城市',
		371600: '滨州市',
		// 371700: '菏泽市',
		410100: '郑州市',
		410200: '开封市',
		410300: '洛阳市',
		410400: '平顶山市',
		410500: '安阳市',
		// 410600: '鹤壁市',
		410700: '新乡市',
		// 410800: '焦作市',
		410900: '濮阳市',
		411000: '许昌市',
		411100: '漯河市',
		411200: '三门峡市',
		// 411300: '南阳市',
		411400: '商丘市',
		411500: '信阳市',
		// 411600: '周口市',
		411700: '驻马店市',
		// 419000: '省直辖县',
		420100: '武汉市',
		// 420200: '黄石市',
		420300: '十堰市',
		420500: '宜昌市',
		420600: '襄阳市',
		// 420700: '鄂州市',
		420800: '荆门市',
		// 420900: '孝感市',
		421000: '荆州市',
		421100: '黄冈市',
		421200: '咸宁市',
		421300: '随州市',
		422800: '恩施土家族苗族自治州',
		// 429000: '省直辖县',
		430100: '长沙市',
		430200: '株洲市',
		// 430300: '湘潭市',
		430400: '衡阳市',
		430500: '邵阳市',
		430600: '岳阳市',
		430700: '常德市',
		430800: '张家界市',
		// 430900: '益阳市',
		// 431000: '郴州市',
		// 431100: '永州市',
		431200: '怀化市',
		431300: '娄底市',
		// 433100: '湘西土家族苗族自治州',
		440100: '广州市',
		440200: '韶关市',
		440300: '深圳市',
		440400: '珠海市',
		440500: '汕头市',
		440600: '佛山市',
		440700: '江门市',
		440800: '湛江市',
		440900: '茂名市',
		// 441200: '肇庆市',
		441300: '惠州市',
		// 441400: '梅州市',
		441500: '汕尾市',
		// 441600: '河源市',
		441700: '阳江市',
		// 441800: '清远市',
		441900: '东莞市',
		442000: '中山市',
		// 445100: '潮州市',
		// 445200: '揭阳市',
		445300: '云浮市',
		450100: '南宁市',
		450200: '柳州市',
		450300: '桂林市',
		450400: '梧州市',
		450500: '北海市',
		// 450600: '防城港市',
		450700: '钦州市',
		// 450800: '贵港市',
		450900: '玉林市',
		451000: '百色市',
		// 451100: '贺州市',
		// 451200: '河池市',
		// 451300: '来宾市',
		451400: '崇左市',
		460100: '海口市',
		460200: '三亚市',
		// 460300: '三沙市',
		// 460400: '儋州市',
		// 469000: '省直辖县',
		500100: '重庆市',
		// 500200: '县',
		510100: '成都市',
		// 510300: '自贡市',
		// 510400: '攀枝花市',
		// 510500: '泸州市',
		// 510600: '德阳市',
		510700: '绵阳市',
		// 510800: '广元市',
		// 510900: '遂宁市',
		// 511000: '内江市',
		// 511100: '乐山市',
		511300: '南充市',
		// 511400: '眉山市',
		// 511500: '宜宾市',
		// 511600: '广安市',
		// 511700: '达州市',
		// 511800: '雅安市',
		// 511900: '巴中市',
		// 512000: '资阳市',
		// 513200: '阿坝藏族羌族自治州',
		// 513300: '甘孜藏族自治州',
		// 513400: '凉山彝族自治州',
		520100: '贵阳市',
		520200: '六盘水市',
		// 520300: '遵义市',
		// 520400: '安顺市',
		// 520500: '毕节市',
		// 520600: '铜仁市',
		// 522300: '黔西南布依族苗族自治州',
		// 522600: '黔东南苗族侗族自治州',
		// 522700: '黔南布依族苗族自治州',
		530100: '昆明市',
		530300: '曲靖市',
		// 530400: '玉溪市',
		// 530500: '保山市',
		// 530600: '昭通市',
		// 530700: '丽江市',
		// 530800: '普洱市',
		// 530900: '临沧市',
		// 532300: '楚雄彝族自治州',
		532500: '红河哈尼族彝族自治州',
		532600: '文山壮族苗族自治州',
		// 532800: '西双版纳傣族自治州',
		532900: '大理白族自治州',
		// 533100: '德宏傣族景颇族自治州',
		// 533300: '怒江傈僳族自治州',
		// 533400: '迪庆藏族自治州',
		// 540100: '拉萨市',
		// 540200: '日喀则市',
		// 540300: '昌都市',
		// 540400: '林芝市',
		// 540500: '山南市',
		// 540600: '那曲市',
		// 542500: '阿里地区',
		610100: '西安市',
		610200: '铜川市',
		610300: '宝鸡市',
		// 610400: '咸阳市',
		610500: '渭南市',
		// 610600: '延安市',
		610700: '汉中市',
		610800: '榆林市',
		610900: '安康市',
		// 611000: '商洛市',
		620100: '兰州市',
		// 620200: '嘉峪关市',
		// 620300: '金昌市',
		// 620400: '白银市',
		// 620500: '天水市',
		// 620600: '武威市',
		620700: '张掖市',
		// 620800: '平凉市',
		// 620900: '酒泉市',
		// 621000: '庆阳市',
		// 621100: '定西市',
		// 621200: '陇南市',
		// 622900: '临夏回族自治州',
		// 623000: '甘南藏族自治州',
		630100: '西宁市',
		// 630200: '海东市',
		// 632200: '海北藏族自治州',
		// 632300: '黄南藏族自治州',
		// 632500: '海南藏族自治州',
		// 632600: '果洛藏族自治州',
		// 632700: '玉树藏族自治州',
		// 632800: '海西蒙古族藏族自治州',
		640100: '银川市',
		// 640200: '石嘴山市',
		// 640300: '吴忠市',
		// 640400: '固原市',
		// 640500: '中卫市',
		// 650100: '乌鲁木齐市',
		// 650200: '克拉玛依市',
		// 650400: '吐鲁番市',
		// 650500: '哈密市',
		// 652300: '昌吉回族自治州',
		// 652700: '博尔塔拉蒙古自治州',
		// 652800: '巴音郭楞蒙古自治州',
		// 652900: '阿克苏地区',
		// 653000: '克孜勒苏柯尔克孜自治州',
		// 653100: '喀什地区',
		// 653200: '和田地区',
		// 654000: '伊犁哈萨克自治州',
		// 654200: '塔城地区',
		// 654300: '阿勒泰地区',
		// 659000: '自治区直辖县级行政区划',
		// 710100: '台北市',
		// 710200: '高雄市',
		// 710300: '台南市',
		// 710400: '台中市',
		// 710500: '金门县',
		// 710600: '南投县',
		// 710700: '基隆市',
		710800: '新竹市',
		710900: '嘉义市',
		// 711100: '新北市',
		// 711200: '宜兰县',
		// 711300: '新竹县',
		// 711400: '桃园县',
		// 711500: '苗栗县',
		// 711700: '彰化县',
		711900: '嘉义县'
		// 712100: '云林县',
		// 712400: '屏东县',
		// 712500: '台东县',
		// 712600: '花莲县',
		// 712700: '澎湖县',
		// 712800: '连江县',
		// 810100: '香港岛',
		// 810200: '九龙',
		// 810300: '新界',
		// 820100: '澳门半岛',
		// 820200: '离岛'
	}
};