var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("img", { staticClass: "banner", attrs: { src: _vm.bannerUrl } }),
    _c(
      "div",
      { staticClass: "order-info" },
      [
        _c("div", { staticClass: "order-info-title border flex f-ai-c" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/icon-1.png") },
          }),
          _c("span", { staticClass: "title" }, [_vm._v("预约信息")]),
          _c("span", { staticClass: "tips t1" }, [_vm._v("(必填)")]),
        ]),
        _c(
          "van-form",
          { ref: "form", attrs: { "validate-first": "" } },
          [
            _c("van-field", {
              attrs: {
                label: "姓名",
                placeholder: "请输入姓名",
                required: "",
                rules: _vm.nameRul,
                clearable: "",
              },
              model: {
                value: _vm.dataForm.name,
                callback: function ($$v) {
                  _vm.$set(_vm.dataForm, "name", $$v)
                },
                expression: "dataForm.name",
              },
            }),
            _c("van-field", {
              attrs: {
                label: "手机号",
                required: "",
                maxlength: 11,
                placeholder: "请输入手机号",
                rules: _vm.mobilePhoneRul,
                clearable: "",
              },
              model: {
                value: _vm.dataForm.mobilePhone,
                callback: function ($$v) {
                  _vm.$set(_vm.dataForm, "mobilePhone", $$v)
                },
                expression: "dataForm.mobilePhone",
              },
            }),
            _c("van-field", {
              attrs: {
                label: "身份证",
                required: "",
                maxlength: 18,
                placeholder: "请输入身份证号",
                rules: _vm.idCardRul,
                clearable: "",
              },
              model: {
                value: _vm.dataForm.idcard,
                callback: function ($$v) {
                  _vm.$set(_vm.dataForm, "idcard", $$v)
                },
                expression: "dataForm.idcard",
              },
            }),
            _vm.dataForm.service === "physicalExam"
              ? _c("van-field", {
                  staticClass: "marital-status",
                  attrs: { name: "radio", label: "婚姻状况", required: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "input",
                        fn: function () {
                          return [
                            _c(
                              "van-radio-group",
                              {
                                attrs: {
                                  direction: "horizontal",
                                  "icon-size": "16px",
                                },
                                model: {
                                  value: _vm.dataForm.maritalStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "maritalStatus", $$v)
                                  },
                                  expression: "dataForm.maritalStatus",
                                },
                              },
                              [
                                _c("van-radio", { attrs: { name: "1" } }, [
                                  _vm._v("未婚"),
                                ]),
                                _c("van-radio", { attrs: { name: "2" } }, [
                                  _vm._v("已婚"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1171887886
                  ),
                })
              : _vm._e(),
            _c("van-field", {
              attrs: {
                readonly: "",
                clickable: "",
                name: "datetimePicker",
                value: _vm.dataForm.visitTime,
                label: "预约时间",
                placeholder: "请选择",
                "right-icon": require("@/assets/images/icon-right.png"),
                rules: _vm.visitTimeRul,
                required: "",
              },
              on: {
                click: function ($event) {
                  _vm.showVisitTimePicker = true
                },
              },
            }),
            _c("van-calendar", {
              attrs: {
                type: "range",
                "allow-same-day": true,
                color: "#2491FF",
              },
              on: { confirm: _vm.onVisitTimeConfirm },
              model: {
                value: _vm.showVisitTimePicker,
                callback: function ($$v) {
                  _vm.showVisitTimePicker = $$v
                },
                expression: "showVisitTimePicker",
              },
            }),
            _c("van-field", {
              attrs: {
                "is-link": "",
                readonly: "",
                label: "预约机构",
                placeholder: "请选择",
                "right-icon": require("@/assets/images/icon-right.png"),
                rules: _vm.hospitalRul,
                required: "",
              },
              on: {
                click: function ($event) {
                  _vm.showHospital = true
                },
              },
              model: {
                value: _vm.dataForm.hospital,
                callback: function ($$v) {
                  _vm.$set(_vm.dataForm, "hospital", $$v)
                },
                expression: "dataForm.hospital",
              },
            }),
            _c(
              "van-popup",
              {
                attrs: { round: "", position: "bottom" },
                model: {
                  value: _vm.showHospital,
                  callback: function ($$v) {
                    _vm.showHospital = $$v
                  },
                  expression: "showHospital",
                },
              },
              [
                _c("van-cascader", {
                  attrs: {
                    title: "预约机构",
                    options: _vm.options,
                    "active-color": "#2491FF",
                  },
                  on: {
                    close: function ($event) {
                      _vm.showHospital = false
                    },
                    change: _vm.onChange,
                    finish: _vm.onFinish,
                  },
                  model: {
                    value: _vm.hospitalValue,
                    callback: function ($$v) {
                      _vm.hospitalValue = $$v
                    },
                    expression: "hospitalValue",
                  },
                }),
              ],
              1
            ),
            _c("van-field", {
              attrs: {
                label: "备注",
                placeholder: "请输入备注",
                clearable: "",
              },
              model: {
                value: _vm.dataForm.remark,
                callback: function ($$v) {
                  _vm.$set(_vm.dataForm, "remark", $$v)
                },
                expression: "dataForm.remark",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "button flex f-jc-c",
        attrs: { "native-type": "submit" },
        on: { click: _vm.commit },
      },
      [
        _c("img", {
          attrs: { src: require("@/assets/images/button.png"), alt: "" },
        }),
        _c("span", [_vm._v("提交")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }